/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from 'react'
import { useDebounce } from 'hooks/useDebounce'
import api from 'services/api'
import * as S from './styles'
import { removeSpecialCharactersAndBlank } from 'utils/removeSpecialCharacters'
import * as T from './types'
import { casting, removeSearch } from './casting'
import { randomKey } from 'utils/randomKey'

const Select = ({
  id,
  label,
  placeholder = 'Selecione...',
  mode,
  timeoutDebounce = 1500,
  endpoint,
  error,
  hasOptionAllItems = false,
  options,
  noContentFoundMessage = 'Não há dados',
  isPaginate = true,
  disabled = false,
  isLoading = false,
  startDefaultOptions = false,
  value,
  isEditing = false,
  allowToClear = false,
  clearValue = false,
  disabledByBackend,
  showCounter = false,
  onChange = () => true,
  readOnly,
  getValueAndLabel = false,
  developerMode_token,
  developerMode_ShowAnExampleEndpoint
}: T.SelectTypes) => {
  const { keyUpString, debounceIsWaiting } = useDebounce()
  const [values, setValues] = useState<T.OptionType[]>()
  const [defaultValues, setDefaultValues] = useState()
  const [asyncOptions, setAsyncOptions] = useState<T.OptionType[]>([])
  const [loading, setLoading] = useState(false)
  const [nextPageURL, setNextPageURL] = useState<string | null>('')
  const [counter, setCounter] = useState(0)
  const [identifier] = useState(() => {
    if (id) return id
    if (label) return removeSpecialCharactersAndBlank(label)
    return ''
  })
  const [isDisabled, setIsDisabled] = useState(false)
  const [asyncOptionOnFirstTime, setAsyncOptionOnFirstTime] = useState(true)
  const [itemsAreDisabled, setItemsAreDisabled] = useState(false)
  const [arrayOptions, setArrayOptions] = useState<
    { label: string; value: number | string }[]
  >([])

  const showCounterInSelect = (values: any) => {
    if (showCounter && (mode === 'multiple' || mode === 'tags')) {
      setCounter(values.length)
    }
  }

  const disabledAllItems = (disabled: boolean) => {
    setItemsAreDisabled(disabled)

    const options = asyncOptions.map((option) => ({
      ...option,
      disabled: option.label === disabledByBackend ? false : disabled
    }))

    setAsyncOptions(options)
  }

  const handleSearch = async (value: any, isDefault?: boolean) => {
    if (value === '') return

    setLoading(true)

    const response = await api.get(
      isDefault ? `${removeSearch(endpoint)}` : `${endpoint}${value}`
    )

    const { data } = response

    setNextPageURL(data.next)

    const result = casting(data, itemsAreDisabled)

    if (hasOptionAllItems) {
      result.unshift({ label: 'Todos(as)', value: -1 })
    }

    setAsyncOptions(result)
    setLoading(false)
  }

  useEffect(() => {
    if (startDefaultOptions && !disabled && endpoint) {
      handleSearch('default', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDefaultOptions, endpoint, disabled, isLoading])

  useEffect(() => {
    if (value && isEditing) {
      setIsDisabled(true)

      let data
      let val
      if (Array.isArray(value)) {
        data = value
        val = !getValueAndLabel ? value.map((val) => val.value) : value
      } else {
        val = value.value
        data = [value]
      }

      if (asyncOptionOnFirstTime && asyncOptions.length === 0) {
        setAsyncOptions(data)
      }

      showCounterInSelect(val)
      setAsyncOptionOnFirstTime(false)
      setDefaultValues(val)
      setIsDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isEditing])

  useEffect(() => {
    if (allowToClear) {
      setValues(undefined)
      setDefaultValues(undefined)
      setCounter(0)
    }
  }, [clearValue, allowToClear])

  useEffect(() => {
    if (value && !endpoint) {
      setIsDisabled(true)
      let val
      if (Array.isArray(value)) {
        val = !getValueAndLabel ? value.map((val) => val.value) : value
      } else {
        val = value.value
      }

      showCounterInSelect(val)
      setDefaultValues(val)
      setIsDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, endpoint])

  const handlePaginate = async () => {
    if (nextPageURL) {
      setLoading(true)
      const response = await api.get(nextPageURL)
      const { data } = response

      setNextPageURL(data.next)

      const result: any = casting(data, itemsAreDisabled)

      setAsyncOptions([...asyncOptions, ...result])
      setLoading(false)
    }
  }

  const handleChange = useCallback(
    (value: any) => {
      onChange(value)
      setValues(value)
      showCounterInSelect(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      asyncOptions,
      disabledByBackend,
      getValueAndLabel,
      onChange,
      showCounterInSelect
    ]
  )

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && isPaginate) {
      !loading && handlePaginate()
    }
  }

  const onSearch = (text: string) => {
    keyUpString(
      text,
      (value: string | number) => {
        handleSearch(value)
      },
      timeoutDebounce
    )
  }

  const positionInHorizontal = (counter: number) => {
    const len = String(counter).length

    switch (len) {
      case 2:
        return -6

      case 3:
        return -9

      case 4:
        return -15

      case 5:
        return -18

      default:
        return -3
    }
  }

  if (disabled || isLoading || isDisabled) {
    return (
      <S.Wrapper>
        {!!label && <S.Label htmlFor={id}>{label}</S.Label>}
        <S.Select disabled={disabled} />
      </S.Wrapper>
    )
  }

  if (endpoint) {
    return (
      <S.Wrapper>
        <S.Badge
          count={counter}
          overflowCount={99999}
          offset={[positionInHorizontal(counter), 0]}
        >
          <S.Select
            showSearch
            allowClear
            labelInValue={getValueAndLabel}
            id={identifier}
            optionFilterProp="children"
            disabled={isLoading || disabled || readOnly}
            placeholder={placeholder}
            onChange={handleChange}
            loading={loading || debounceIsWaiting}
            value={values || defaultValues}
            mode={mode}
            onPopupScroll={handleScroll}
            onSearch={onSearch}
            notFoundContent={
              <S.NoContentFound>
                {loading || debounceIsWaiting
                  ? 'Carregando...'
                  : noContentFoundMessage}
              </S.NoContentFound>
            }
          >
            {asyncOptions.map((option) => (
              <S.Option
                key={`${option.value}_${randomKey()}`}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </S.Option>
            ))}
          </S.Select>
        </S.Badge>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <S.Badge
        count={counter}
        overflowCount={99999}
        offset={[positionInHorizontal(counter), 0]}
      >
        <S.Select
          showSearch
          allowClear
          labelInValue={getValueAndLabel}
          id={identifier}
          disabled={isLoading || disabled || readOnly}
          optionFilterProp="children"
          placeholder={placeholder}
          onChange={handleChange}
          value={values || defaultValues}
          mode={mode}
          onPopupScroll={handleScroll}
          notFoundContent={
            <S.NoContentFound>{noContentFoundMessage}</S.NoContentFound>
          }
        >
          {options &&
            options.map((option) => (
              <S.Option
                key={`${option.value}_${randomKey()}`}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </S.Option>
            ))}
        </S.Select>
      </S.Badge>
    </S.Wrapper>
  )
}

export default Select

/**
 * https://sistema.staging.valora.cc/api/bus/algorithms/?company_owner=2&search=
 * 804185d9e203a9a4dbf23e111148404c91c091bc
 */
