import * as S from './styles'
import { Button, Input, Switch } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import FormWrapper from 'components/molecules/FormWrapper'
import { useEffect, useState } from 'react'
import { useStudents } from 'contexts/students'

type ParamsType = {
  id: string
}

function Edit(): JSX.Element {
  const [options] = useState([
    {
      label: 'Bronze',
      value: 'Bronze'
    },
    {
      label: 'Prata',
      value: 'Prata'
    },
    {
      label: 'Ouro',
      value: 'Ouro'
    }
  ])
  const { id } = useParams<ParamsType>()
  const { control, handleSubmit, setValue } = useForm()
  const {
    handleEdit = () => true,
    loading,
    handleShow = () => true,
    initialData,
    showLoading
  } = useStudents()

  useEffect(() => {
    if (id) {
      handleShow(id)
    }
  }, [handleShow, id])

  useEffect(() => {
    if (initialData) {
      // console.log({ initialData })

      setValue('name', initialData.name)
      setValue('phone', initialData.phone)
      setValue('email', initialData.email)
      setValue('school', initialData.school)
      setValue('city', initialData.city)
      setValue('state', initialData.state)
      setValue('credit_fixes', initialData.credit_fixes)
      setValue('access_type', initialData.access_type)
      setValue('raphael_student', initialData.raphael_student)
      setValue('access_type', initialData.access_type)
      // console.log(initialData)
    }
  }, [initialData, options, setValue])

  const onSubmit = (data: any) => {
    // console.log(data)
    handleEdit(data, id)
  }

  console.log({ showLoading })

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={loading}
        title="Editar Aluno"
        backTo="/students"
      >
        <S.FieldWrapper>
          <S.Label>Nome</S.Label>
          <Controller
            as={<Input placeholder="João da Silva" />}
            name="name"
            control={control}
          />
        </S.FieldWrapper>
        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>E-mail</S.Label>
            <Controller
              as={<Input placeholder="joaodasilva@horadaredacao.com" />}
              name="email"
              control={control}
            />
          </S.FieldWrapper>
          <S.FieldWrapper>
            <S.Label>WhatsApp</S.Label>
            <Controller
              as={
                <NumberFormat
                  customInput={Input}
                  placeholder="(XX) XXXXX-XXXX"
                  format="(##) #####-####"
                />
              }
              name="phone"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>
        <S.FieldWrapper>
          <S.Label>Escola</S.Label>
          <Controller
            as={<Input placeholder="Curso Hora da Redação" />}
            name="school"
            control={control}
          />
        </S.FieldWrapper>
        <S.DoubleColumn>
          <S.DoubleColumn className="column">
            <S.DoubleColumn>
              <S.FieldWrapper>
                <S.Label>Cidade</S.Label>
                <Controller
                  as={<Input placeholder="Rio de Janeiro" />}
                  name="city"
                  control={control}
                />
              </S.FieldWrapper>
              <S.FieldWrapper>
                <S.Label>Estado</S.Label>
                <Controller
                  as={<Input placeholder="RJ" />}
                  name="state"
                  control={control}
                />
              </S.FieldWrapper>
            </S.DoubleColumn>
            <S.FieldWrapper>
              {!showLoading && (
                <>
                  <S.Label>Selecione o plano</S.Label>
                  <Controller
                    as={
                      <S.Select
                        options={options}
                        value={
                          initialData && {
                            label: initialData.access_type,
                            value: initialData.access_type
                          }
                        }
                      />
                    }
                    name="access_type"
                    control={control}
                    defaultValue={
                      initialData && {
                        label: initialData.access_type,
                        value: initialData.access_type
                      }
                    }
                  />
                </>
              )}
            </S.FieldWrapper>
            <S.SwitchWrapper>
              {!showLoading && (
                <>
                  <Controller
                    render={(field) => (
                      <Switch
                        onChange={(checked: any) => field.onChange(checked)}
                        defaultChecked={
                          !!(initialData && initialData.raphael_student)
                        }
                      />
                    )}
                    name="raphael_student"
                    control={control}
                    defaultValue={
                      !!(initialData && initialData.raphael_student)
                    }
                  />
                  <S.Label>Aluno do Raphael</S.Label>
                </>
              )}
            </S.SwitchWrapper>
          </S.DoubleColumn>

          <S.DoubleColumn>
            <S.FieldWrapper>
              <S.Label>Crédito correções</S.Label>
              <Controller
                as={<Input type="number" />}
                name="credit_fixes"
                control={control}
              />
            </S.FieldWrapper>
          </S.DoubleColumn>
        </S.DoubleColumn>
        <S.Footer>
          <Link to="/students">
            <Button htmlType="button" disabled={loading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={loading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Edit
