export type SearchSelectOptions = {
  label: string
  value: string | number
}

export const formatter = (options: any) => {
  if (Array.isArray(options)) {
    if (typeof options[0] === 'number' || typeof options[0] === 'string')
      return options

    let isNumber = false
    const valuesNumber: number[] = []
    const valuesString: string[] = []

    options.forEach((option) => {
      if (Number.isInteger(option.value)) {
        valuesNumber.push(Number(option.value))
        isNumber = true
      } else {
        if (option.value) {
          valuesString.push(option.value.toString())
        }
      }
    })

    return isNumber ? valuesNumber : valuesString
  }

  if (typeof options === 'number' || typeof options === 'string') {
    return options
  }

  return options?.value
}

/** Receive new Date to returns iso format */
export const dateFormatter = (date: Date | undefined): string | undefined => {
  return date?.toISOString()
}

/** Receive date string and time string to join in Date format to returns iso format */
export const joinDateAndFormatter = (date: string, time: string): string => {
  const preDate = new Date(`${date} ${time}`)

  return preDate.toISOString()
}

export const isLessTen = (number: number): string => {
  if (number < 10) return `0${number}`

  return String(number)
}

export const dateBrFormatter = (date: Date): string => {
  return new Date(date).toLocaleString('pt-BR', {
    timeZone: 'America/Sao_Paulo'
  })
}

export const dateBrToUsFormatter = (date: string): string => {
  const arr = date.split('/')
  return `${arr[2]}-${arr[1]}-${arr[0]}`
}

type KeywordsFormatter = {
  key: string | number
}

export const keywordFormatter = (
  keywords: SearchSelectOptions[]
): KeywordsFormatter[] => {
  return keywords.map((keyword) => ({
    key: keyword.value
  }))
}

export const numberFormatter = (value: string | number): number => {
  if (typeof value === 'number') return value
  return Number(
    String(value)
      .replace(/[%R$.]+/gi, '')
      .replace(',', '.')
  )
}

export const currencyBrFormatter = (value: number): string => {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}
