import * as S from './styles'
import { Button, Tabs, Collapse, Switch, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import ThemeFormTexts from 'components/organisms/ThemeFormTexts'
import FormWrapper from 'components/molecules/FormWrapper'
import { useThemes } from 'contexts/themes'
const { TabPane } = Tabs

const { Panel } = Collapse

function Add() {
  const { control, handleSubmit } = useForm()
  const { handleCreate = () => true, loading } = useThemes()

  const onSubmit = (data: any) => {
    handleCreate(data)
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={loading}
        title="Adicionar Tema"
        backTo="/themes"
      >
        <S.FieldWrapper>
          <S.Label>Tema</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>
        <S.SwitchWrapper>
          <Controller
            render={(field) => (
              <Switch onChange={(checked: any) => field.onChange(checked)} />
            )}
            name="gold_published"
            control={control}
          />
          <S.Label>Publicar ouro</S.Label>
        </S.SwitchWrapper>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Categoria" key="1">
            <Controller
              as={
                <S.Select
                  options={[
                    {
                      label: 'ENEM',
                      value: 'enem'
                    },
                    {
                      label: 'Temas objetivos',
                      value: 'Temas objetivos'
                    },
                    {
                      label: 'Temas subjetivos',
                      value: 'Temas subjetivos'
                    },
                    {
                      label: 'Concurso',
                      value: 'Concurso'
                    }
                  ]}
                />
              }
              name="category"
              control={control}
            />
          </TabPane>
          <TabPane tab="Introdução" key="2">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Introdução I" key="1">
                <ThemeFormTexts
                  preName="introductions"
                  index={0}
                  control={control}
                />
              </Panel>
              <Panel header="Introdução II" key="2">
                <ThemeFormTexts
                  preName="introductions"
                  index={1}
                  control={control}
                />
              </Panel>
              <Panel header="Introdução III" key="3">
                <ThemeFormTexts
                  preName="introductions"
                  index={2}
                  control={control}
                />
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="Desenvolvimento I" key="3">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Desenvolvimento I.I" key="1">
                <ThemeFormTexts
                  preName="developments_1"
                  index={0}
                  control={control}
                />
              </Panel>
              <Panel header="Desenvolvimento I.II" key="2">
                <ThemeFormTexts
                  preName="developments_1"
                  index={1}
                  control={control}
                />
              </Panel>
              <Panel header="Desenvolvimento I.III" key="3">
                <ThemeFormTexts
                  preName="developments_1"
                  index={2}
                  control={control}
                />
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="Desenvolvimento II" key="4">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Desenvolvimento II.I" key="1">
                <ThemeFormTexts
                  preName="developments_2"
                  index={0}
                  control={control}
                />
              </Panel>
              <Panel header="Desenvolvimento II.II" key="2">
                <ThemeFormTexts
                  preName="developments_2"
                  index={1}
                  control={control}
                />
              </Panel>
              <Panel header="Desenvolvimento II.III" key="3">
                <ThemeFormTexts
                  preName="developments_2"
                  index={2}
                  control={control}
                />
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="Conclusão" key="5">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Conclusão I" key="1">
                <ThemeFormTexts
                  preName="conclusion"
                  index={0}
                  control={control}
                  hasFifth
                />
              </Panel>
              <Panel header="Conclusão II" key="2">
                <ThemeFormTexts
                  preName="conclusion"
                  index={1}
                  control={control}
                  hasFifth
                />
              </Panel>
              <Panel header="Conclusão III" key="3">
                <ThemeFormTexts
                  preName="conclusion"
                  hasFifth
                  index={2}
                  control={control}
                />
              </Panel>
            </Collapse>
          </TabPane>
        </Tabs>
        <S.Footer>
          <Link to="/themes">
            <Button htmlType="button" disabled={loading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={loading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Add
