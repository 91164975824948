import { notification } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'
import { logout } from 'services/userAuth'

export type UserProps = {
  email?: string
  username?: string
  is_superuser?: BooleanConstructor
  children?: React.ReactNode
}

type UserFormType = {
  email: string
  password: string
}

type UserContextType = {
  loading: boolean
  isAuthenticated: boolean
  signIn?: (data: UserFormType) => void
  signOut?: () => void
  user: UserType
}

type UserType = {
  name: string
  email: string
  id: string
}

const userContextDefaultValue: UserContextType = {
  loading: false,
  isAuthenticated: false,
  user: {
    name: '',
    email: '',
    id: ''
  }
}

const AuthContext = createContext<UserContextType>(userContextDefaultValue)

export const AuthProvider = ({ children }: UserProps) => {
  const history = useHistory()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<UserType>({
    name: '',
    email: '',
    id: ''
  })

  const signIn = useCallback(
    async (data: UserFormType) => {
      setLoading(true)
      const response = await api.post('/auth', data)
      setLoading(false)

      if (response.status >= 400) return

      const { name, token, is_superadmin, email, id } = response.data

      if (!is_superadmin) {
        notification.warning({
          message: 'Atenção',
          description: 'Usuário não autorizado para esta área'
        })
        return
      }

      const userData = {
        name,
        email,
        id
      }

      localStorage.setItem('HORADAREDACAO_AUTH', token)
      localStorage.setItem('HORADAREDACAO_USER', JSON.stringify(userData))

      setUser(userData)
      setIsAuthenticated(true)
      history.push('/home')
    },
    [history]
  )

  const signOut = useCallback(async () => {
    logout()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
        user,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
