import styled, { css } from 'styled-components'
import {
  BarChartOutlined,
  FileTextOutlined,
  UserOutlined,
  VideoCameraAddOutlined,
  BookOutlined,
  AudioOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'
import { BiEraser, HiOutlineSpeakerphone, FaRunning } from 'styles/icons'

export const iconsCss = css`
  font-size: 23px;
  margin-right: 10px;
`

export const HomeIcon = styled(BarChartOutlined)`
  ${iconsCss}
`
export const ThemeIcon = styled(FileTextOutlined)`
  ${iconsCss}
`
export const StudentIcon = styled(UserOutlined)`
  ${iconsCss}
`
export const VideoIcon = styled(VideoCameraAddOutlined)`
  ${iconsCss}
`

export const BookIcon = styled(BookOutlined)`
  ${iconsCss}
`

export const PodcastIcon = styled(AudioOutlined)`
  ${iconsCss}
`

export const CorrectionsIcons = styled(BiEraser)`
  ${iconsCss}
`

export const SuggestionsIcon = styled(HiOutlineSpeakerphone)`
  ${iconsCss}
`
export const UsersIcon = styled(UsergroupAddOutlined)`
  ${iconsCss}
`

export const MarathonIcon = styled(FaRunning)`
  ${iconsCss}
`

export const Container = styled.aside`
  width: 250px;
  height: 100%;
  background: rgb(245, 245, 245);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #ab4345;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #9a4332;
  }
`
export const Image = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const Logo = styled.img.attrs({
  src: '/logo-l.png'
})`
  max-width: 80px;
`
export const Items = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 20px;
`

export const ListItems = styled.ul`
  list-style-type: none;

  a {
    color: inherit;
  }
`

export const Item = styled.li`
  width: 100%;
  padding: 13px;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    background: rgb(200, 200, 200);
  }
`
