import styled from 'styled-components'
import { Select as AntdSelect, Badge as AntdBadge } from 'antd'

export const { Option } = AntdSelect

export const Wrapper = styled.div`
  width: 100%;
`

export const Select = styled(AntdSelect)`
  width: 100%;
  font-size: 16px;
`

export const Badge = styled(AntdBadge)`
  width: 100%;
  font-size: 16px;
`

export const Label = styled.label`
  width: 100%;
  overflow: hidden;
  height: 14px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  margin-bottom: 5px;
  cursor: pointer;
`
export const Error = styled.p`
  color: red;
  font-size: 12;
`
export const NoContentFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
