import { Route, Switch } from 'react-router-dom'
import PrivateRoute from 'components/molecules/PrivateRoute'
import * as Page from 'pages'

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Page.default} />
      <Route path="/login" exact component={Page.Login} />

      <PrivateRoute path="/home" exact component={Page.Home} />

      <PrivateRoute path="/themes" exact component={Page.Themes} />
      <PrivateRoute path="/themes/add" exact component={Page.ThemesAdd} />
      <PrivateRoute path="/themes/edit/:id" exact component={Page.ThemesEdit} />

      <PrivateRoute path="/students" exact component={Page.Students} />
      <PrivateRoute
        path="/students/edit/:id"
        exact
        component={Page.StudentsEdit}
      />

      <PrivateRoute path="/videos" exact component={Page.Videos} />
      <PrivateRoute path="/videos/add" exact component={Page.VideosAdd} />
      <PrivateRoute path="/videos/edit/:id" exact component={Page.VideosEdit} />

      <PrivateRoute path="/ebooks" exact component={Page.Ebooks} />
      <PrivateRoute path="/ebooks/add" exact component={Page.EbooksAdd} />
      <PrivateRoute path="/ebooks/edit/:id" exact component={Page.EbooksEdit} />

      <PrivateRoute path="/suggestions" exact component={Page.Suggestions} />
      <PrivateRoute
        path="/suggestions/:id"
        exact
        component={Page.SuggestionsEdit}
      />

      <PrivateRoute path="/podcasts" exact component={Page.Audios} />
      <PrivateRoute path="/podcasts/add" exact component={Page.AudiosAdd} />
      <PrivateRoute
        path="/podcasts/edit/:id"
        exact
        component={Page.AudiosEdit}
      />

      <PrivateRoute path="/users" exact component={Page.Users} />
      <PrivateRoute path="/users/add" exact component={Page.UsersAdd} />
      <PrivateRoute path="/users/edit/:id" exact component={Page.UsersEdit} />

      <PrivateRoute path="/corrections" exact component={Page.Corrections} />

      <PrivateRoute path="/marathon" exact component={Page.Marathon} />
      <PrivateRoute path="/marathon/add" exact component={Page.MarathonAdd} />
      <PrivateRoute
        path="/marathon/edit/:id"
        exact
        component={Page.MarathonEdit}
      />

      <PrivateRoute path="/enter-code" exact component={Page.EnterCode} />
      <PrivateRoute
        path="/enter-code/add"
        exact
        component={Page.EnterCodeAdd}
      />
      <PrivateRoute path="/tutorials" exact component={Page.Tutorials} />
    </Switch>
  )
}
