import { message, notification } from 'antd'
import { useProgress } from 'contexts/progress'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { joinDateAndFormatter } from 'utils/formatter'
import { mappersMarathon } from 'utils/mappers'
import * as T from './types'

const MarathonContext = createContext<T.MarathonContextType>(
  T.marathonContextDefaultValue
)

export const MarathonProvider = ({ children }: T.MarathonProps) => {
  const { changeProgress } = useProgress()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState<T.PaginationType>({
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  })

  const handleList = useCallback(
    async (page = 1, pageSize = 10, search?: string) => {
      setLoading(true)
      const filter: any = {
        listAll: true,
        page,
        size: pageSize
      }

      if (search) {
        filter.search = search
      }

      const url = new URLSearchParams(filter).toString()

      const response = await api.get(`/marathon/?${url}`)
      setLoading(false)

      if (response.status >= 400) return

      const { total, next_page, previous_page, marathons } = response.data

      setPagination({
        total,
        next_page,
        previous_page,
        current_page: page,
        pageSize,
        search
      })

      setResources(marathons)
    },
    []
  )

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    setShowLoading(true)
    const response = await api.get(`/marathon/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)

    setShowLoading(false)
  }, [])

  const handleCreate = useCallback(
    async (data: any) => {
      const { dataToSave, error } = await mappersMarathon(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      changeProgress(1, 10)

      data.release_date = joinDateAndFormatter(data.date, data.time)
      const image = data.image
      const file = data.file
      const audio = data.audio

      delete data.image
      delete data.file
      delete data.audio
      delete data.date
      delete data.time

      changeProgress(13, 20)

      setFormLoading(true)
      const response = await api.post('/marathon', dataToSave)

      changeProgress(25, 40)

      const { id } = response.data

      const dataFile = new FormData()
      dataFile.append('file', file.file.originFileObj)
      dataFile.append('id', id)

      const dataImage = new FormData()
      dataImage.append('image', image.file.originFileObj)
      dataImage.append('id', id)

      const dataAudio = new FormData()
      dataAudio.append('audio', audio.file.originFileObj)
      dataAudio.append('id', id)

      await api.post('/marathon/file', dataFile)
      changeProgress(45, 55)
      await api.post('/marathon/image', dataImage)
      changeProgress(60, 90)
      await api.post('/marathon/audio', dataAudio)
      changeProgress(100)

      setFormLoading(false)

      if (response.status >= 400) return

      history.push('/marathon')
      message.success('Item da maratona criado com sucesso!')
    },
    [changeProgress, history]
  )

  const handleEdit = useCallback(
    async (data: any, id: string) => {
      setFormLoading(true)

      const { dataToSave, error } = await mappersMarathon(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      data.release_date = joinDateAndFormatter(data.date, data.time)
      const image = data.image
      const file = data.file
      const audio = data.audio

      delete data.image
      delete data.file
      delete data.audio
      delete data.date
      delete data.time

      changeProgress(13, 20)
      setFormLoading(true)
      const response = await api.put(`/marathon/${id}`, dataToSave)

      changeProgress(25, 40)

      if (file) {
        const dataFile = new FormData()
        dataFile.append('file', file.file.originFileObj)
        dataFile.append('id', id)

        if (initialData.pdf) {
          await api.patch('/marathon/file', dataFile)
        } else {
          await api.post('/marathon/file', dataFile)
        }
      }

      changeProgress(45, 55)

      if (image) {
        const dataImage = new FormData()
        dataImage.append('image', image.file.originFileObj)
        dataImage.append('id', id)

        if (initialData.image) {
          await api.patch('/marathon/image', dataImage)
        } else {
          await api.post('/marathon/image', dataImage)
        }
      }

      changeProgress(60, 90)

      if (audio) {
        const dataAudio = new FormData()
        dataAudio.append('audio', audio.file.originFileObj)
        dataAudio.append('id', id)

        if (initialData.audio) {
          await api.patch('/marathon/audio', dataAudio)
        } else {
          await api.post('/marathon/audio', dataAudio)
        }
      }

      changeProgress(100)

      setFormLoading(false)

      if (response.status >= 400) return
      history.push('/marathon')
      message.success('Item da maratona editado com sucesso!')
    },
    [changeProgress, history, initialData]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/marathon/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Item da maratona deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <MarathonContext.Provider
      value={{
        loading,
        pagination,
        formLoading,
        resources,
        handleList,
        handleShow,
        handleCreate,
        handleEdit,
        handleDelete,
        initialData,
        showLoading
      }}
    >
      {children}
    </MarathonContext.Provider>
  )
}

export const useMarathon = () => useContext(MarathonContext)
