import { youtubeParser } from 'utils/youtubeParser'
import * as S from './styles'
import youtubeVideos, { YoutubeVideosYype } from './data'
import { Modal } from 'antd'
import { useState } from 'react'

function Tutorials() {
  const [video, setVideo] = useState<YoutubeVideosYype | undefined>(undefined)

  const handleStop = () => {
    const iframe = document.querySelector('iframe')
    if (iframe) {
      const iframeSrc = iframe.src
      iframe.src = iframeSrc
    }
  }

  return (
    <S.Container>
      <S.Layout title="Tutoriais">
        <S.Videos>
          {youtubeVideos.map((video) => (
            <S.Video onClick={() => setVideo(video)} key={video.url}>
              <S.Thumb
                src={`https://img.youtube.com/vi/${youtubeParser(
                  video.url
                )}/mqdefault.jpg`}
              />
              <S.Texts>
                <S.Title>{video.title}</S.Title>
                <S.Desc>{video.description}</S.Desc>
              </S.Texts>
            </S.Video>
          ))}
        </S.Videos>
      </S.Layout>
      <Modal
        title={video?.title}
        visible={!!video}
        onOk={() => {
          setVideo(undefined)
          handleStop()
        }}
        onCancel={() => {
          setVideo(undefined)
          handleStop()
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={800}
      >
        {video && (
          <iframe
            style={{ width: '100%', height: 500 }}
            src={`https://www.youtube.com/embed/${youtubeParser(video?.url)}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </Modal>
    </S.Container>
  )
}

export default Tutorials
