import { useEffect } from 'react'
import * as S from './styles'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { useUsers } from 'contexts/users'

function Users() {
  const {
    handleList = () => true,
    resources,
    loading,
    handleDelete = () => true
  } = useUsers()

  useEffect(() => {
    handleList()
  }, [handleList])

  return (
    <S.Container>
      <S.Layout
        title="Usuários"
        buttons={
          <Link to="/users/add">
            <S.Button>
              <PlusOutlined />
              Adicionar
            </S.Button>
          </Link>
        }
      >
        <S.List
          dataSource={resources}
          loading={loading}
          pagination={{ hideOnSinglePage: true }}
          rowKey="id"
        >
          <S.Column title="Nome" dataIndex="name" key="name" />
          <S.Column title="E-mail" dataIndex="email" key="email" />
          <S.Column title="Telefone" dataIndex="phone" key="phone" />
          <S.Column
            title="Ação"
            dataIndex="id"
            key="id"
            render={(id) => (
              <>
                <Link to={`/users/edit/${id}`}>
                  <S.Button>Editar</S.Button>
                </Link>
                <S.Confirm
                  title="Tem certeza que deseja deletar?"
                  onConfirm={() => handleDelete(id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <S.Button>Apagar</S.Button>
                </S.Confirm>
              </>
            )}
          />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default Users
