import { message, notification } from 'antd'
import { useProgress } from 'contexts/progress'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { mappersEbook } from 'utils/mappers'
import * as T from './types'

const EBooksContext = createContext<T.EBooksContextType>(
  T.eBooksContextDefaultValue
)

export const EBooksProvider = ({ children }: T.EBooksProps) => {
  const [loading, setLoading] = useState(false)
  const { changeProgress } = useProgress()
  const history = useHistory()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState<T.PaginationType>({
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  })

  const handleList = useCallback(
    async (page = 1, size = 10, search?: string) => {
      setLoading(true)
      const filter: any = {
        listAll: true,
        page,
        size
      }

      if (search) {
        filter.search = search
      }

      const url = new URLSearchParams(filter).toString()

      const response = await api.get(`/ebooks/?${url}`)
      setLoading(false)

      if (response.status >= 400) return

      const { total, next_page, previous_page, ebooks } = response.data

      setPagination({
        total,
        next_page,
        previous_page,
        current_page: page,
        pageSize: size,
        search
      })

      setResources(ebooks)
    },
    []
  )

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    setShowLoading(true)
    const response = await api.get(`/ebooks/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)

    setShowLoading(false)
  }, [])

  const handleCreate = useCallback(
    async (data: any) => {
      const { dataToSave, error } = await mappersEbook(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      const image = data.image
      const file = data.file

      delete data.image
      delete data.file
      changeProgress(5, 30)
      setFormLoading(true)
      const response = await api.post('/ebooks', dataToSave)
      changeProgress(50, 60)
      const { id } = response.data

      const dataFile = new FormData()
      const dataImage = new FormData()

      console.log({ file })

      dataFile.append('file', file.file.originFileObj)
      dataFile.append('id', id)

      dataImage.append('image', image.file.originFileObj)
      dataImage.append('id', id)

      await api.post('/ebooks/file', dataFile)
      changeProgress(60, 80)
      await api.post('/ebooks/image', dataImage)
      changeProgress(100)
      setFormLoading(false)

      if (response.status >= 400) return

      history.push('/ebooks')
      message.success('Ebook criado com sucesso!')
    },
    [changeProgress, history]
  )

  const handleEdit = useCallback(
    async (data: any, id: string) => {
      setFormLoading(true)

      if (typeof data.category === 'object') {
        data.category = data.category.value
      }

      const { dataToSave, error } = await mappersEbook(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      const image = data.image
      const file = data.file

      delete data.image
      delete data.file
      changeProgress(5, 30)
      setFormLoading(true)
      const response = await api.put(`/ebooks/${id}`, dataToSave)
      changeProgress(50, 60)
      if (file) {
        const dataFile = new FormData()
        dataFile.append('file', file.file.originFileObj)
        dataFile.append('id', id)
        await api.patch('/ebooks/file', dataFile)
      }
      changeProgress(60, 80)
      if (image) {
        const dataImage = new FormData()
        dataImage.append('image', image.file.originFileObj)
        dataImage.append('id', id)
        await api.patch('/ebooks/image', dataImage)
      }
      changeProgress(100)
      setFormLoading(false)

      if (response.status >= 400) return
      history.push('/ebooks')
      message.success('Ebook editado com sucesso!')
    },
    [changeProgress, history]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/ebooks/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Ebook deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <EBooksContext.Provider
      value={{
        loading,
        pagination,
        formLoading,
        resources,
        handleList,
        handleShow,
        handleCreate,
        handleEdit,
        handleDelete,
        initialData,
        showLoading
      }}
    >
      {children}
    </EBooksContext.Provider>
  )
}

export const useEbooks = () => useContext(EBooksContext)
