import * as S from './styles'
import { Button, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { useEnterCode } from 'contexts/enterCode'

function Add() {
  const { control, handleSubmit } = useForm()
  const { handleCreate = () => true, loading } = useEnterCode()

  const onSubmit = (data: any) => {
    handleCreate(data)
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={loading}
        title="Adicionar Cupom"
        backTo="/enter-code"
      >
        <S.FieldWrapper>
          <S.Label>Código do Cupom</S.Label>
          <Controller as={<Input />} name="code" control={control} />
          <span style={{ color: 'red' }}>
            Caracteres permitidos: de A ao Z, de 0 a 9, @ e # (letras não podem
            ter acentos ou espaços em branco e não pode conter Ç). <br />
            Exemplo: {'ouro#1000'}
          </span>
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Data de expiração</S.Label>
          <Controller
            as={<Input type="date" />}
            name="date"
            control={control}
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Hora de expiração</S.Label>
          <Controller
            as={<Input type="time" />}
            name="time"
            control={control}
          />
        </S.FieldWrapper>

        <S.Footer>
          <Link to="/enter-code">
            <Button htmlType="button" disabled={loading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={loading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Add
