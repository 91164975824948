/* eslint-disable react/display-name */
import * as S from './styles'

function Home() {
  const fakeData = [
    {
      key: '1',
      position: '1º',
      name: 'Patrícia de Oliveira',
      media: '1000'
    },
    {
      key: '2',
      position: '2',
      name: 'Valentina dos Santos',
      media: '950'
    }
  ]

  return (
    <S.Container>
      <S.Layout title="Ranking (em desenvolvimento)">
        <S.List dataSource={fakeData}>
          <S.Column title="Posição" dataIndex="position" key="position" />
          <S.Column title="Nome" dataIndex="name" key="name" />
          <S.Column title="Média" dataIndex="media" key="media" />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default Home
