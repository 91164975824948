import { message, notification } from 'antd'
import { useProgress } from 'contexts/progress'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { mappersUsers } from 'utils/mappers'
import * as T from './types'

const UsersContext = createContext<T.UsersContextType>(
  T.usersContextDefaultValue
)

export const UsersProvider = ({ children }: T.UsersProps) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { changeProgress } = useProgress()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [showLoading, setShowLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pagination, setPagination] = useState<T.PaginationType>({
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  })

  const handleList = useCallback(async () => {
    setLoading(true)

    const response = await api.get(`/user`)
    setLoading(false)

    if (response.status >= 400) return

    setResources(response.data)
  }, [])

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    setShowLoading(true)
    const response = await api.get(`/user/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)

    setShowLoading(false)
  }, [])

  const handleCreate = useCallback(
    async (data: any) => {
      const { dataToSave, error } = await mappersUsers(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      const image = data.image
      delete data.image
      delete data.profile

      changeProgress(5, 30)
      setFormLoading(true)
      const response = await api.post('/user', dataToSave)
      changeProgress(50, 60)
      setFormLoading(false)
      if (response.status >= 400) return

      const { id } = response.data

      if (image) {
        setFormLoading(true)
        const dataImage = new FormData()
        dataImage.append('image', image.file.originFileObj)
        dataImage.append('id', id)

        await api.post('/user/avatar', dataImage)
      }

      changeProgress(100)

      setFormLoading(false)

      if (response.status >= 400) return

      history.push('/users')
      message.success('Usuário criado com sucesso!')
    },
    [changeProgress, history]
  )

  const handleEdit = useCallback(
    async (data: any, id: string) => {
      const { dataToSave, error } = await mappersUsers(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      const image = data.image
      delete data.image
      delete data.profile
      changeProgress(5, 30)
      setFormLoading(true)
      const response = await api.put(`/user/${id}`, dataToSave)
      changeProgress(50, 60)
      if (image) {
        const dataImage = new FormData()
        dataImage.append('image', image.file.originFileObj)
        dataImage.append('id', id)

        if (initialData.avatar) {
          await api.patch('/user/avatar', dataImage)
        } else {
          await api.post('/user/avatar', dataImage)
        }
      }
      changeProgress(100)
      setFormLoading(false)

      if (response.status >= 400) return
      history.push('/users')
      message.success('Usuário editado com sucesso!')
    },
    [changeProgress, history, initialData]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/user/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Usuário deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <UsersContext.Provider
      value={{
        loading,
        pagination,
        formLoading,
        resources,
        handleList,
        handleShow,
        handleCreate,
        handleEdit,
        handleDelete,
        initialData,
        showLoading
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export const useUsers = () => useContext(UsersContext)
