import { message, notification } from 'antd'
import { useProgress } from 'contexts/progress'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { mappersThemes } from 'utils/mappers'
import * as T from './types'

const ThemeContext = createContext<T.ThemeContextType>(
  T.themeContextDefaultValue
)

export const ThemeProvider = ({ children }: T.ThemeProps) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { changeProgress } = useProgress()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [pagination, setPagination] = useState<T.PaginationType>({
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  })

  const handleList = useCallback(
    async (page = 1, pageSize = 10, search?: string) => {
      setLoading(true)
      let querySearch = ''

      if (search) {
        querySearch = `&search=${search}`
      }

      const response = await api.get(
        `/themes?page=${page}&size=${pageSize}${querySearch}`
      )
      setLoading(false)

      if (response.status >= 400) return

      const { total, next_page, previous_page, themes } = response.data

      setPagination({
        total,
        next_page,
        previous_page,
        current_page: page,
        pageSize,
        search
      })

      setResources(themes)
    },
    []
  )

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    const response = await api.get(`/themes/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)
  }, [])

  const handleCreate = useCallback(
    async (data: any) => {
      console.log(data)

      const { dataToSave, error } = await mappersThemes(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      console.log(dataToSave)

      setFormLoading(true)
      changeProgress(30, 50)
      const response = await api.post('/themes', dataToSave)
      setFormLoading(false)
      changeProgress(100)
      if (response.status >= 400) return

      history.push('/themes')
      message.success('Tema criado com sucesso!')
    },
    [changeProgress, history]
  )

  const handleEdit = useCallback(
    async (data: any, id: string) => {
      setFormLoading(true)

      const { conclusion, development_1, development_2, introduction } =
        initialData

      if (!('conclusion' in data)) {
        data = {
          ...data,
          conclusion: conclusion
        }
      }

      for (let i = 0; i < data.conclusion.length; i++) {
        const id = data.conclusion[i].id

        const index = conclusion.findIndex((intr: any) => intr.id === id)

        if (index > -1) {
          console.log({
            intr: conclusion[index],
            dt: data.conclusion[i]
          })
          conclusion[index] = data.conclusion[i]
        }
      }
      data.conclusion = conclusion

      if (!('developments_1' in data)) {
        data = {
          ...data,
          developments_1: development_1
        }
      }

      for (let i = 0; i < data.developments_1.length; i++) {
        const id = data.developments_1[i].id

        const index = development_1.findIndex((intr: any) => intr.id === id)

        if (index > -1) {
          console.log({
            intr: development_1[index],
            dt: data.developments_1[i]
          })
          development_1[index] = data.developments_1[i]
        }
      }
      data.developments_1 = development_1

      if (!('developments_2' in data)) {
        data = {
          ...data,
          developments_2: development_2
        }
      }

      for (let i = 0; i < data.developments_2.length; i++) {
        const id = data.developments_2[i].id

        const index = development_2.findIndex((intr: any) => intr.id === id)

        if (index > -1) {
          console.log({
            intr: development_2[index],
            dt: data.developments_2[i]
          })
          development_2[index] = data.developments_2[i]
        }
      }
      data.developments_2 = development_2

      if (!('introductions' in data)) {
        data = {
          ...data,
          introductions: introduction
        }
      }

      for (let i = 0; i < data.introductions.length; i++) {
        const id = data.introductions[i].id

        const index = introduction.findIndex((intr: any) => intr.id === id)

        if (index > -1) {
          console.log({
            intr: introduction[index],
            dt: data.introductions[i]
          })
          introduction[index] = data.introductions[i]
        }
      }
      data.introductions = introduction

      if (typeof data.category !== 'string') {
        data.category = data.category.value
      }

      changeProgress(30, 50)
      const response = await api.put(`/themes/${id}`, data)
      changeProgress(100)
      setFormLoading(false)

      if (response.status >= 400) return
      history.push('/themes')
      message.success('Tema editado com sucesso!')
    },
    [changeProgress, history, initialData]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/themes/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Tema deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <ThemeContext.Provider
      value={{
        loading,
        pagination,
        formLoading,
        resources,
        handleList,
        handleShow,
        handleCreate,
        handleEdit,
        handleDelete,
        initialData
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useThemes = () => useContext(ThemeContext)
