import { message, notification } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { joinDateAndFormatter } from 'utils/formatter'

import * as T from './types'

const EnterCode = createContext<T.EnterCodeType>(T.enterCodeDefaultValue)

export const EntercodeProvider = ({ children }: T.EnterCodeProps) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])

  const handleList = useCallback(async () => {
    setLoading(true)

    const response = await api.get(`/enter-code`)
    setLoading(false)

    if (response.status >= 400) return

    setResources(response.data)
  }, [])

  const handleCreate = useCallback(
    async ({ code, date, time }: any) => {
      if (!code || !date || !time) {
        notification.error({
          message: 'Atenção',
          description: 'Há campos vazios no formulário'
        })
        return
      }

      const allowed = /[^0-9A-Za-z#@]*/g
      const acents = /[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]/g
      const blank = /\s/g

      console.log({
        code,
        allow: !allowed.test(code),
        acent: acents.test(code),
        blank: blank.test(code)
      })

      if (!allowed.test(code)) {
        notification.error({
          message: 'Atenção',
          description: 'Cupom inválido'
        })
        return
      }

      if (blank.test(code)) {
        notification.error({
          message: 'Atenção',
          description: 'Cupom inválido'
        })
        return
      }

      if (acents.test(code)) {
        notification.error({
          message: 'Atenção',
          description: 'Cupom inválido'
        })
        return
      }

      setFormLoading(true)
      const validity = joinDateAndFormatter(date, time)

      const response = await api.post('/enter-code', {
        code,
        validity
      })
      setFormLoading(false)

      if (response.status >= 400) return

      history.push('/enter-code')
      message.success('Cupom criado com sucesso!')
    },
    [history]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/enter-code/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Cupom deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <EnterCode.Provider
      value={{
        loading,

        formLoading,
        resources,
        handleList,

        handleCreate,

        handleDelete
      }}
    >
      {children}
    </EnterCode.Provider>
  )
}

export const useEnterCode = () => useContext(EnterCode)
