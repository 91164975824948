import { useEffect } from 'react'
import * as S from './styles'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useThemes } from 'contexts/themes'

function Themes() {
  const {
    handleList = () => true,
    resources,
    loading,
    handleDelete = () => true,
    pagination
  } = useThemes()

  useEffect(() => {
    handleList()
  }, [handleList])

  return (
    <S.Container>
      <S.Layout
        title="Temas"
        searchable
        onSearch={(value) => handleList(1, 10, value)}
        buttons={
          <Link to="/themes/add">
            <S.Button>
              <PlusOutlined />
              Adicionar
            </S.Button>
          </Link>
        }
      >
        <S.List
          dataSource={resources}
          loading={loading}
          rowKey="id"
          onChange={(pagination) =>
            handleList(pagination.current, pagination.pageSize)
          }
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            total: pagination.total,
            current: pagination.current_page
          }}
        >
          <S.Column title="Título" dataIndex="name" key="name" />
          <S.Column title="Categoria" dataIndex="category" key="category" />
          <S.Column
            title="Publicado Ouro"
            dataIndex="gold_published"
            key="gold_published"
            render={(gold_published) => (
              <>{gold_published && <S.CheckIcon />}</>
            )}
          />
          <S.Column
            title="Ação"
            dataIndex="id"
            key="id"
            render={(id) => (
              <>
                <Link to={`/themes/edit/${id}`}>
                  <S.Button>Editar</S.Button>
                </Link>
                <S.Confirm
                  title="Tem certeza que deseja deletar?"
                  onConfirm={() => handleDelete(id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <S.Button>Apagar</S.Button>
                </S.Confirm>
              </>
            )}
          />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default Themes
