import * as S from './styles'
import { Menu, Dropdown } from 'antd'
import { useAuth } from 'contexts/login'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import HeaderLoader from 'components/atoms/HeaderLoader'

function Header() {
  const { signOut } = useAuth()
  const [user] = useState(() => {
    const data = JSON.parse(localStorage.getItem('HORADAREDACAO_USER') || '')

    return data
  })

  const menu = (
    <Menu>
      <Link to="/tutorials">
        <Menu.Item>Tutoriais</Menu.Item>
      </Link>
      <Link to="/enter-code">
        <Menu.Item>Cupons</Menu.Item>
      </Link>
      <Menu.Item onClick={signOut}>Sair</Menu.Item>
    </Menu>
  )

  return (
    <S.Container>
      <Dropdown overlay={menu} placement="bottomCenter">
        <S.Button>
          <S.UserIcons />
          {user.name}
        </S.Button>
      </Dropdown>
      <HeaderLoader />
    </S.Container>
  )
}

export default Header
