import styled from 'styled-components'
import { Button as AntdButton } from 'antd'
import { UserOutlined } from '@ant-design/icons'

export const Container = styled.div`
  width: 100%;
  height: 70px;
  background: rgb(245, 245, 245);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`

export const Button = styled(AntdButton)`
  padding-left: 15px;
  padding-right: 15px;
`
export const UserIcons = styled(UserOutlined)``
