import { useEffect } from 'react'
import * as S from './styles'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { dateBrFormatter } from 'utils/formatter'
import { useEbooks } from 'contexts/ebooks'

function EBooks() {
  const {
    handleList = () => true,
    resources,
    loading,
    handleDelete = () => true,
    pagination
  } = useEbooks()

  useEffect(() => {
    handleList()
  }, [handleList])

  return (
    <S.Container>
      <S.Layout
        title="E-Books"
        searchable
        onSearch={(value) => handleList(1, 10, value)}
        buttons={
          <Link to="/ebooks/add">
            <S.Button>
              <PlusOutlined />
              Adicionar
            </S.Button>
          </Link>
        }
      >
        <S.List
          dataSource={resources}
          loading={loading}
          onChange={(pagination) =>
            handleList(pagination.current, pagination.pageSize)
          }
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            total: pagination.total,
            current: pagination.current_page
          }}
          rowKey="id"
        >
          <S.Column title="Título" dataIndex="name" key="name" />
          <S.Column title="Categoria" dataIndex="category" key="category" />
          <S.Column title="Ordem" dataIndex="order" key="order" />
          <S.Column
            title="Publicado Ouro"
            dataIndex="gold_published"
            key="gold_published"
            render={(gold_published, data: any) => (
              <>
                {gold_published && (
                  <Tooltip
                    title={
                      data.gold_published
                        ? `Publicado para ouro em: ${dateBrFormatter(
                            data.gold_published_at
                          )}`
                        : 'Data não encontrada'
                    }
                  >
                    <S.CheckIcon />
                  </Tooltip>
                )}
              </>
            )}
          />
          <S.Column
            title="Publicado"
            dataIndex="published"
            key="published"
            render={(published, data: any) => (
              <>
                {published && (
                  <Tooltip
                    title={
                      data.published_at
                        ? `Publicado em: ${dateBrFormatter(data.published_at)}`
                        : 'Data não encontrada'
                    }
                  >
                    <S.CheckIcon />
                  </Tooltip>
                )}
              </>
            )}
          />
          <S.Column
            title="Ação"
            dataIndex="id"
            key="id"
            render={(id, data: any) => (
              <>
                <S.ExternalURl href={data.pdf}>
                  <S.Button>Visualizar</S.Button>
                </S.ExternalURl>
                <Link to={`/ebooks/edit/${id}`}>
                  <S.Button>Editar</S.Button>
                </Link>
                <S.Confirm
                  title="Tem certeza que deseja deletar?"
                  onConfirm={() => handleDelete(id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <S.Button>Apagar</S.Button>
                </S.Confirm>
              </>
            )}
          />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default EBooks
