import { useEffect } from 'react'
import * as S from './styles'
import {
  AudioOutlined,
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  PlusOutlined,
  YoutubeOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { dateBrFormatter } from 'utils/formatter'
import { useMarathon } from 'contexts/marathon'

function Marathon() {
  const {
    handleList = () => true,
    resources,
    loading,
    handleDelete = () => true,
    pagination
  } = useMarathon()

  useEffect(() => {
    handleList(1, 10)
  }, [handleList])

  return (
    <S.Container>
      <S.Layout
        title="Maratona"
        searchable
        onSearch={(value) => handleList(1, 10, value)}
        buttons={
          <Link to="/marathon/add">
            <S.Button>
              <PlusOutlined />
              Adicionar
            </S.Button>
          </Link>
        }
      >
        <S.List
          dataSource={resources}
          loading={loading}
          onChange={(pagination) =>
            handleList(pagination.current, pagination.pageSize)
          }
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            total: pagination.total,
            current: pagination.current_page
          }}
          rowKey="id"
        >
          <S.Column title="Ordem" dataIndex="order" key="order" />
          <S.Column title="Título" dataIndex="name" key="name" />
          <S.Column
            title="Data de lançamento"
            dataIndex="release_date"
            key="release_date"
            render={(release_date) => (
              <span>{dateBrFormatter(release_date)}</span>
            )}
          />
          {/* <S.Column
            title="Áudio Publicado"
            dataIndex="audio_published"
            key="audio_published"
            render={(audio_published, data: any) => (
              <>
                {audio_published && (
                  <Tooltip
                    placement="left"
                    title={
                      data.audio_published
                        ? `Áudio publicado em: ${dateBrFormatter(
                            data.audio_published_at
                          )}`
                        : 'Data não encontrada'
                    }
                  >
                    <S.CheckIcon />
                  </Tooltip>
                )}
              </>
            )}
          /> */}
          <S.Column
            title="Publicado"
            dataIndex="published"
            key="published"
            render={(published, data: any) => (
              <>
                {published && (
                  <Tooltip
                    title={
                      data.published_at
                        ? `Publicado em: ${dateBrFormatter(data.published_at)}`
                        : 'Data não encontrada'
                    }
                  >
                    <S.CheckIcon />
                  </Tooltip>
                )}
              </>
            )}
          />
          <S.Column
            title="Ação"
            dataIndex="id"
            key="id"
            render={(id, data: any) => (
              <>
                <S.ExternalURl href={data.video_url}>
                  <Tooltip title="Abrir vídeo">
                    <S.Button>
                      <YoutubeOutlined />
                    </S.Button>
                  </Tooltip>
                </S.ExternalURl>
                <S.ExternalURl href={data.audio}>
                  <Tooltip title="Baixar audio">
                    <S.Button>
                      <AudioOutlined />
                    </S.Button>
                  </Tooltip>
                </S.ExternalURl>
                <S.ExternalURl href={data.image}>
                  <Tooltip title="Abrir imagem">
                    <S.Button>
                      <FileImageOutlined />
                    </S.Button>
                  </Tooltip>
                </S.ExternalURl>
                <S.ExternalURl href={data.pdf}>
                  <Tooltip title="Abrir PDF">
                    <S.Button>
                      <FilePdfOutlined />
                    </S.Button>
                  </Tooltip>
                </S.ExternalURl>
                <Link to={`/marathon/edit/${id}`}>
                  <Tooltip title="Editar">
                    <S.Button>
                      <EditOutlined />
                    </S.Button>
                  </Tooltip>
                </Link>
                <S.Confirm
                  title="Tem certeza que deseja deletar?"
                  onConfirm={() => handleDelete(id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Tooltip title="Deletar">
                    <S.Button>
                      <DeleteOutlined />
                    </S.Button>
                  </Tooltip>
                </S.Confirm>
              </>
            )}
          />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default Marathon
