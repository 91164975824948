import { useState, useEffect } from 'react'
import * as S from './styles'
import { Button, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { useSuggestions } from 'contexts/suggestions'

const { TextArea } = Input

type ParamsType = {
  id: string
}

function Edit() {
  const { id } = useParams<ParamsType>()
  const [options] = useState([
    'ENEM',
    'UERJ',
    'Saúde',
    'Evolução',
    'Vestibulares'
  ])
  const { control, setValue } = useForm()
  const { handleShow = () => true, initialData } = useSuggestions()

  useEffect(() => {
    if (id) {
      handleShow(id)
    }
  }, [handleShow, id])

  useEffect(() => {
    if (initialData) {
      setValue('theme', initialData.theme)
      setValue('user_name', initialData.users.name)
      setValue('user_email', initialData.users.email)
      setValue('user_phone', initialData.users.phone)
    }
  }, [initialData, options, setValue])

  return (
    <S.Form>
      <FormWrapper title="Visualizar Sugestão" backTo="/suggestions">
        <S.FieldWrapper>
          <S.Label>Tema sugerido</S.Label>
          <Controller
            as={<TextArea rows={5} readOnly />}
            name="theme"
            control={control}
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Nome do usuário</S.Label>
          <Controller
            as={<Input readOnly />}
            name="user_name"
            control={control}
          />
        </S.FieldWrapper>
        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Telefone do usuário</S.Label>
            <Controller
              as={<Input readOnly />}
              name="user_phone"
              control={control}
            />
          </S.FieldWrapper>
          <S.FieldWrapper>
            <S.Label>E-mail do usuário</S.Label>
            <Controller
              as={<Input readOnly />}
              name="user_email"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>
        <S.Footer>
          <Link to="/suggestions">
            <Button htmlType="button" type="text">
              Voltar
            </Button>
          </Link>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Edit
