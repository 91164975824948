export type SuggestionsProps = {
  name?: string
  id?: string
  children?: React.ReactNode
}

export type SuggestionsContextType = {
  loading: boolean
  initialData: any
  resources: any[]

  showLoading: boolean
  handleList: () => void
  handleShow: (id: string) => Promise<string | void>
  handleDelete: (id: string) => void
}

export type PaginationType = {
  total: number
  next_page: number
  previous_page: number
  current_page: number
  pageSize: number
  search?: string
}

export const suggestionsContextDefaultValue: SuggestionsContextType = {
  loading: false,
  resources: [],
  initialData: undefined,
  showLoading: false,
  handleList: () => true,
  handleShow: async () => '',
  handleDelete: () => true
}
