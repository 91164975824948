export const exts = [
  'pdf',
  'jpg',
  'jpeg',
  'eps',
  'indd',
  'raw',
  'png',
  'gif',
  'webp',
  'tiff',
  'psd',
  'eps',
  'psd',
  'ai',
  'm4a',
  'mp3',
  'flac',
  'wav',
  'wma',
  'aac'
]

export const audioFiles = [
  'audio/m4a',
  'audio/mp3',
  'audio/flac',
  'audio/wav',
  'audio/wma',
  'audio/aac',
  'audio/mpeg'
]

export const imageFiles = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/tiff'
]

export const filesCompositions = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/tiff',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]
