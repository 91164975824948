import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import * as S from './styles'

type FormWrapperType = {
  title: string
  backTo: string
  disabledBack?: boolean
  children: React.ReactNode
}

function FormWrapper({
  title,
  backTo,
  disabledBack = false,
  children
}: FormWrapperType) {
  return (
    <S.Container>
      <S.Header>
        <S.Title>{title}</S.Title>
        <Link to={backTo}>
          <Button disabled={disabledBack}>
            <ArrowLeftOutlined />
            Voltar
          </Button>
        </Link>
      </S.Header>
      <S.Content>{children}</S.Content>
    </S.Container>
  )
}

export default FormWrapper
