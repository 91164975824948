import Routes from 'Routes'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from 'styles/globalStyles'
import { AuthProvider } from 'contexts/login'
import { ThemeProvider } from 'contexts/themes'
import { StudentsProvider } from 'contexts/students'
import { VideosProvider } from 'contexts/videos'
import { EBooksProvider } from 'contexts/ebooks'
import { AudiosProvider } from 'contexts/audios'
import { SuggestionsProvider } from 'contexts/suggestions'
import { UsersProvider } from 'contexts/users'
import { MarathonProvider } from 'contexts/marathon'
import { ProgressProvider } from 'contexts/progress'
import { EntercodeProvider } from 'contexts/enterCode'
import { CompositionsProvider } from 'contexts/compositions'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ProgressProvider>
          <StudentsProvider>
            <VideosProvider>
              <EBooksProvider>
                <AudiosProvider>
                  <SuggestionsProvider>
                    <MarathonProvider>
                      <EntercodeProvider>
                        <CompositionsProvider>
                          <UsersProvider>
                            <ThemeProvider>
                              <GlobalStyles />
                              <Routes />
                            </ThemeProvider>
                          </UsersProvider>
                        </CompositionsProvider>
                      </EntercodeProvider>
                    </MarathonProvider>
                  </SuggestionsProvider>
                </AudiosProvider>
              </EBooksProvider>
            </VideosProvider>
          </StudentsProvider>
        </ProgressProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
