import styled, { css } from 'styled-components'
import { Input, Skeleton } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
export const Container = styled.div``

export const Header = styled.header`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`
export const Title = styled.h2`
  font-size: 23px;
  font-weight: bold;
`
export const AreaButtons = styled.div`
  display: flex;
`

export const Content = styled.div`
  padding: 10px;
`
export const { Search } = Input

export const Loader = styled(Skeleton)``

export const CloseIcon = styled(CloseCircleOutlined)<{ isVisible?: boolean }>`
  font-size: 12px;
  color: #1890ff;
  visibility: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
    `}
`
