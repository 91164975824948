import { useState } from 'react'
import * as S from './styles'
import { Button, Upload, message, Switch, Input, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { imageFiles } from 'utils/fileExtensions'
import { useEbooks } from 'contexts/ebooks'

function Add() {
  const [options] = useState([
    'PPT Interdisciplinar',
    'Temas semanais',
    'E-books',
    'Redações Exemplares'
  ])
  const { control, handleSubmit } = useForm()
  const { handleCreate = () => true, formLoading } = useEbooks()
  const [file, setFile] = useState<any>()
  const [image, setImage] = useState<any>()

  const onSubmit = (data: any) => {
    if (!file || !image) {
      notification.warning({
        message: 'Atenção',
        description: 'para salvar é necessário enviar os arquivos'
      })
      return
    }

    handleCreate({ ...data, image, file })
  }

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={formLoading}
        title="Adicionar E-book"
        backTo="/ebooks"
      >
        <S.SwitchWrapper>
          <Controller
            render={(field) => (
              <Switch onChange={(checked: any) => field.onChange(checked)} />
            )}
            name="gold_published"
            control={control}
          />
          <S.Label>Publicar ouro</S.Label>
        </S.SwitchWrapper>
        <S.SwitchWrapper>
          <Controller
            render={(field) => (
              <Switch onChange={(checked: any) => field.onChange(checked)} />
            )}
            name="published"
            control={control}
          />
          <S.Label>Publicar</S.Label>
        </S.SwitchWrapper>
        <S.DoubleColumn className="together bottom">
          <Upload
            name="file"
            action=""
            fileList={file ? file.fileList : undefined}
            maxCount={1}
            onChange={(info) => setFile(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (file.type !== 'application/pdf') {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload do file</Button>
          </Upload>
          <Upload
            name="image"
            action=""
            fileList={image ? image.fileList : undefined}
            maxCount={1}
            onChange={(info) => setImage(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (!imageFiles.includes(file.type)) {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return imageFiles.includes(file.type) ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload da imagem</Button>
          </Upload>
        </S.DoubleColumn>
        <S.FieldWrapper>
          <S.Label>Título</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>

        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Ordem</S.Label>
            <Controller
              as={<Input placeholder="1" type="number" />}
              name="order"
              control={control}
            />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Label>Categoria</S.Label>
            <Controller
              as={
                <S.Select
                  options={options.map((option) => ({
                    label: option,
                    value: option
                  }))}
                />
              }
              name="category"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>
        <S.Footer>
          <Link to="/ebooks">
            <Button htmlType="button" disabled={formLoading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={formLoading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Add
