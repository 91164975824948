import { useCallback, useEffect, useState } from 'react'
import { Button, Modal, Upload, message, Input } from 'antd'
import * as S from './styles'
import { dateBrFormatter } from 'utils/formatter'
import { useCompositions } from 'contexts/compositions'
import { UploadOutlined } from '@ant-design/icons'

function Videos() {
  const {
    loading,
    data,
    loadPage = () => true,
    handleDelete = () => true,
    handlePost = () => true
  } = useCompositions()

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const [modal, setModal] = useState(false)
  const [image, setImage] = useState<any>()
  const [grade, setGrade] = useState<any>()
  const [id, setId] = useState<any>(undefined)
  const handleCorretion = useCallback((id: string) => {
    setModal(true)
    setId(id)
  }, [])

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  const sendFile = () => {
    if (!image || !grade) {
      message.warn(
        'Para enviar a redação, preencha o formulário com a nota e a correção'
      )

      return
    }

    handlePost({ file: image, id, grade })
    setModal(false)

    setTimeout(() => {
      setImage(undefined)
    }, 1500)
  }

  return (
    <>
      <Modal
        visible={modal}
        okText="Enviar"
        title="Correção de redação"
        onCancel={() => {
          setModal(false)
          setImage(undefined)
        }}
        onOk={() => sendFile()}
      >
        <S.WrapperModal>
          <Input
            onChange={(e) => setGrade(e.target.value)}
            value={grade}
            placeholder="Digite a nota aqui"
            type="number"
          />

          <Upload
            name="image"
            action=""
            fileList={image ? image.fileList : undefined}
            maxCount={1}
            onChange={(info) => setImage(handleChangeFile(info))}
          >
            <Button icon={<UploadOutlined />}>Upload da Redação</Button>
          </Upload>
        </S.WrapperModal>
      </Modal>
      <S.Container>
        <S.Layout title="Correções">
          <S.List
            dataSource={data}
            loading={loading}
            pagination={false}
            rowKey="id"
          >
            <S.Column
              title="Aluno"
              dataIndex="name"
              key="name"
              render={(name, data: any) => <span>{data.users.name}</span>}
            />
            <S.Column
              title="Data de envio"
              dataIndex="created_at"
              key="created_at"
              render={(created_at) => (
                <span>{dateBrFormatter(created_at)}</span>
              )}
            />
            <S.Column
              title="Nota"
              dataIndex="grade"
              key="grade"
              render={(grade) => <>{grade ? <span>{grade}</span> : '-'}</>}
            />
            <S.Column
              title="Ação"
              dataIndex="id"
              key="id"
              render={(id, data: any) => (
                <>
                  <S.Anchor href={data.composition_file}>
                    <S.Button>Ver</S.Button>
                  </S.Anchor>
                  {data.correction_file && (
                    <S.Anchor href={data.correction_file}>
                      <S.Button>Correção</S.Button>
                    </S.Anchor>
                  )}

                  <S.Button onClick={() => handleCorretion(id)}>
                    Corrigir
                  </S.Button>

                  <S.Confirm
                    title="Tem certeza que deseja deletar?"
                    onConfirm={() => handleDelete(id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <S.Button>Apagar</S.Button>
                  </S.Confirm>
                </>
              )}
            />
          </S.List>
        </S.Layout>
      </S.Container>
    </>
  )
}

export default Videos
