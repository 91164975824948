import styled from 'styled-components'
import { Input, Space, Button as AntdButton } from 'antd'
import { UserOutlined } from '@ant-design/icons'

export const Container = styled.form`
  width: 100%;
  height: 100vh;
  min-height: 550px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(249, 247, 247);
`

export const Content = styled.div`
  width: 95%;
  max-width: 700px;
  height: 500px;
  border-radius: 12px;

  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
`

export const Field = styled(Input)``
export const UserIcon = styled(UserOutlined)``

export const Spaced = styled(Space)`
  width: 90%;
`

export const Logo = styled.img.attrs({
  src: `/logo-l.png`
})`
  width: 150px;
  margin-bottom: 50px;
`

export const Button = styled(AntdButton)``
