import { OptionType, Options } from './types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const casting = (
  data: any,
  itemsAreDisabled?: boolean
): OptionType[] => {
  let result = []

  result = data.map(({ id, label, value, name, number, key }: Options) => ({
    value: id || value,
    label: label || name || key || number,
    disabled: itemsAreDisabled || false
  }))

  return result
}

export const removeSearch = (text?: string): string | undefined => {
  if (text) return text.replace('&search=', '')

  return text
}

export const castingKeywords = (data: any): OptionType[] => {
  let result = []

  result = data.map(({ label, name, number, key }: Options) => ({
    value: label || name || key || number,
    label: label || name || key || number
  }))

  return result
}
