import { message } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import * as T from './types'

const SuggestionsContext = createContext<T.SuggestionsContextType>(
  T.suggestionsContextDefaultValue
)

export const SuggestionsProvider = ({ children }: T.SuggestionsProps) => {
  const [loading, setLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [showLoading, setShowLoading] = useState(false)

  const handleList = useCallback(async () => {
    setLoading(true)

    const response = await api.get(`/suggestions`)
    setLoading(false)

    const { data } = response

    for (let i = 0; i < data.length; i++) {
      const theme = `${data[i].theme}`

      if (theme.length > 25) data[i].theme = `${theme.slice(0, 25).trim()}...`

      data[i].user_name = data[i].users.name
    }

    if (response.status >= 400) return

    setResources(response.data)
  }, [])

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    setShowLoading(true)
    const response = await api.get(`/suggestions/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)

    setShowLoading(false)
  }, [])

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/suggestions/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Sugestão deletada com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <SuggestionsContext.Provider
      value={{
        loading,

        resources,
        handleList,
        handleShow,
        handleDelete,
        initialData,
        showLoading
      }}
    >
      {children}
    </SuggestionsContext.Provider>
  )
}

export const useSuggestions = () => useContext(SuggestionsContext)
