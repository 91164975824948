import * as S from './styles'
import { Button, Tabs, Collapse, Switch, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import ThemeFormTexts from 'components/organisms/ThemeFormTexts'
import FormWrapper from 'components/molecules/FormWrapper'
import { useThemes } from 'contexts/themes'
import { useEffect, useState } from 'react'
const { TabPane } = Tabs

const { Panel } = Collapse

type ParamsType = {
  id: string
}

function Edit() {
  const [options] = useState([
    {
      label: 'ENEM',
      value: 'ENEM'
    },
    {
      label: 'Temas objetivos',
      value: 'Temas objetivos'
    },
    {
      label: 'Temas subjetivos',
      value: 'Temas subjetivos'
    },
    {
      label: 'Concurso',
      value: 'Concurso'
    }
  ])
  const { id } = useParams<ParamsType>()
  const { control, handleSubmit, setValue } = useForm()
  const {
    handleEdit = () => true,
    loading,
    handleShow = () => true,
    initialData
  } = useThemes()

  useEffect(() => {
    if (id) {
      handleShow(id)
    }
  }, [handleShow, id])

  useEffect(() => {
    if (initialData) {
      // console.log({ initialData })

      setValue('name', initialData.name)
      setValue('conclusion', initialData.conclusion)
    }
  }, [initialData, options, setValue])

  const onSubmit = (data: any) => {
    handleEdit(data, id)
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper disabledBack={loading} title="Editar Tema" backTo="/themes">
        <S.FieldWrapper>
          <S.Label>Tema</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>
        <S.SwitchWrapper>
          {initialData && (
            <>
              <Controller
                render={(field) => (
                  <Switch
                    onChange={(checked: any) => field.onChange(checked)}
                    defaultChecked={
                      !!(initialData && initialData.gold_published)
                    }
                  />
                )}
                name="gold_published"
                control={control}
                defaultValue={!!(initialData && initialData.gold_published)}
              />

              <S.Label>Publicar ouro</S.Label>
            </>
          )}
        </S.SwitchWrapper>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Categoria" key="1">
            {initialData && (
              <Controller
                as={
                  <S.Select
                    options={options}
                    value={
                      initialData && {
                        label: initialData.category,
                        value: initialData.category
                      }
                    }
                  />
                }
                name="category"
                control={control}
                defaultValue={
                  initialData && {
                    label: initialData.category,
                    value: initialData.category
                  }
                }
              />
            )}
          </TabPane>
          <TabPane tab="Introdução" key="2">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Introdução I" key="1">
                <ThemeFormTexts
                  preName="introductions"
                  name="Introdução I"
                  index={0}
                  control={control}
                  defaultValues={initialData && initialData.introduction[0]}
                />
              </Panel>
              <Panel header="Introdução II" key="2">
                <ThemeFormTexts
                  preName="introductions"
                  name="Introdução II"
                  index={1}
                  control={control}
                  defaultValues={initialData && initialData.introduction[1]}
                />
              </Panel>
              <Panel header="Introdução III" key="3">
                <ThemeFormTexts
                  preName="introductions"
                  name="Introdução III"
                  index={2}
                  control={control}
                  defaultValues={initialData && initialData.introduction[2]}
                />
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="Desenvolvimento I" key="3">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Desenvolvimento I.I" key="1">
                <ThemeFormTexts
                  preName="developments_1"
                  name="Desenvolvimento I.I"
                  index={0}
                  control={control}
                  defaultValues={initialData && initialData.development_1[0]}
                />
              </Panel>
              <Panel header="Desenvolvimento I.II" key="2">
                <ThemeFormTexts
                  preName="developments_1"
                  name="Desenvolvimento I.II"
                  index={1}
                  control={control}
                  defaultValues={initialData && initialData.development_1[1]}
                />
              </Panel>
              <Panel header="Desenvolvimento I.III" key="3">
                <ThemeFormTexts
                  preName="developments_1"
                  name="Desenvolvimento I.III"
                  index={2}
                  control={control}
                  defaultValues={initialData && initialData.development_1[2]}
                />
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="Desenvolvimento II" key="4">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Desenvolvimento II.I" key="1">
                <ThemeFormTexts
                  preName="developments_2"
                  name="Desenvolvimento II.I"
                  index={0}
                  control={control}
                  defaultValues={initialData && initialData.development_2[0]}
                />
              </Panel>
              <Panel header="Desenvolvimento II.II" key="2">
                <ThemeFormTexts
                  preName="developments_2"
                  name="Desenvolvimento II.II"
                  index={1}
                  control={control}
                  defaultValues={initialData && initialData.development_2[1]}
                />
              </Panel>
              <Panel header="Desenvolvimento II.III" key="3">
                <ThemeFormTexts
                  preName="developments_2"
                  name="Desenvolvimento II.III"
                  index={2}
                  control={control}
                  defaultValues={initialData && initialData.development_2[2]}
                />
              </Panel>
            </Collapse>
          </TabPane>
          <TabPane tab="Conclusão" key="5">
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Conclusão I" key="1">
                <ThemeFormTexts
                  preName="conclusion"
                  name="Conclusão I"
                  index={0}
                  control={control}
                  defaultValues={initialData && initialData.conclusion[0]}
                  hasFifth
                />
              </Panel>
              <Panel header="Conclusão II" key="2">
                <ThemeFormTexts
                  preName="conclusion"
                  name="Conclusão II"
                  index={1}
                  control={control}
                  defaultValues={initialData && initialData.conclusion[1]}
                  hasFifth
                />
              </Panel>
              <Panel header="Conclusão III" key="3">
                <ThemeFormTexts
                  preName="conclusion"
                  name="Conclusão III"
                  hasFifth
                  index={2}
                  control={control}
                  defaultValues={initialData && initialData.conclusion[2]}
                />
              </Panel>
            </Collapse>
          </TabPane>
        </Tabs>
        <S.Footer>
          <Link to="/themes">
            <Button htmlType="button" disabled={loading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={loading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Edit
