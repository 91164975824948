import { message } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import api from 'services/api'
import { useProgress } from './progress'

export type CompositionsProps = {
  children?: React.ReactNode
}

type CompositionsType = {
  loading: boolean
  formLoading: boolean
  loadPage?: () => void
  handlePost?: (data: any) => void
  handleDelete?: (id: any) => void
  data: any
}

const compositionsContextDefaultValue: CompositionsType = {
  loading: true,
  formLoading: true,
  data: []
}

const CompositionsContext = createContext<CompositionsType>(
  compositionsContextDefaultValue
)

export const CompositionsProvider = ({ children }: CompositionsProps) => {
  const { changeProgress } = useProgress()
  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const [data, setData] = useState<any>([])

  const loadPage = useCallback(async () => {
    setLoading(true)

    const response = await api.get('/compositions/admin')
    setLoading(false)

    if (response.status >= 400) return

    setData(response.data)
  }, [])

  const handlePost = useCallback(
    async (data: any) => {
      setFormLoading(true)

      const formData = new FormData()

      console.log(data.file)

      formData.append('grade', data.grade)
      formData.append('correction_file', data.file.file.originFileObj)

      changeProgress(25, 60)
      const response = await api.put(`/compositions/${data.id}`, formData)

      changeProgress(100)
      setFormLoading(false)
      message.success('Redação corrigida com sucesso!')

      if (response.status >= 400) return

      loadPage()
    },
    [changeProgress, loadPage]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      const response = await api.delete(`/compositions/${id}`)
      if (response.status >= 400) return
      message.success('Redação deletada com sucesso!')
      loadPage()
    },
    [loadPage]
  )

  return (
    <CompositionsContext.Provider
      value={{
        loading,
        loadPage,
        formLoading,
        handleDelete,
        handlePost,
        data
      }}
    >
      {children}
    </CompositionsContext.Provider>
  )
}

export const useCompositions = () => useContext(CompositionsContext)
