import * as S from './styles'
import { useForm, Controller } from 'react-hook-form'
import { useAuth } from 'contexts/login'

function Login() {
  const { signIn = () => true } = useAuth()
  const { handleSubmit, control } = useForm()

  const onSubmit = (data: any) => {
    signIn(data)
  }

  return (
    <S.Container onSubmit={handleSubmit(onSubmit)}>
      <S.Content>
        <S.Logo />
        <S.Spaced direction="vertical" size="large">
          <Controller
            as={
              <S.Field
                placeholder="Usuário"
                type="email"
                prefix={<S.UserIcon />}
                required
              />
            }
            name="email"
            control={control}
          />
          <Controller
            as={<S.Field.Password placeholder="Senha" />}
            name="password"
            control={control}
            required
          />

          <S.Button type="primary" htmlType="submit" shape="round" size="large">
            Entrar
          </S.Button>
        </S.Spaced>
      </S.Content>
    </S.Container>
  )
}

export default Login
