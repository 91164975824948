import * as yup from 'yup'

type ResponseMappers = {
  dataToSave?: any
  error: any
}

export const mappersThemes = async (data: any): Promise<ResponseMappers> => {
  if (
    !('conclusion' in data) ||
    !('introductions' in data) ||
    !('developments_1' in data) ||
    !('developments_2' in data)
  ) {
    return {
      dataToSave: undefined,
      error: {
        errors: ['Há campos vazios no formulário']
      }
    }
  }

  const schema = yup.object().shape({
    name: yup.string().required('digite o nome do tema'),
    category: yup.string().required('selecione a categoria'),
    conclusion: yup.array().of(
      yup.object().shape({
        paragraph: yup.string().required('digite o paragrafo'),
        grade: yup.string().required('digite a nota'),
        patch_competence_1: yup
          .string()
          .required('na conslusão: digite a descrição da correção'),
        patch_competence_2: yup
          .string()
          .required('na conslusão: digite a descrição da correção'),
        patch_competence_3: yup
          .string()
          .required('na conslusão: digite a descrição da correção'),
        patch_competence_4: yup
          .string()
          .required('na conslusão: digite a descrição da correção')
      })
    ),
    introduction: yup.array().of(
      yup.object().shape({
        paragraph: yup.string().required('digite o paragrafo'),
        grade: yup.string().required('digite a nota'),
        patch_competence_1: yup
          .string()
          .required('na introdução: digite a descrição da correção'),
        patch_competence_2: yup
          .string()
          .required('na introdução: digite a descrição da correção'),
        patch_competence_3: yup
          .string()
          .required('na introdução: digite a descrição da correção'),
        patch_competence_4: yup
          .string()
          .required('na introdução: digite a descrição da correção')
      })
    ),
    developments_1: yup.array().of(
      yup.object().shape({
        paragraph: yup.string().required('digite o paragrafo'),
        grade: yup.string().required('digite a nota'),
        patch_competence_1: yup
          .string()
          .required('em desenvolvimento I: digite a descrição da correção'),
        patch_competence_2: yup
          .string()
          .required('em desenvolvimento I: digite a descrição da correção'),
        patch_competence_3: yup
          .string()
          .required('em desenvolvimento I: digite a descrição da correção'),
        patch_competence_4: yup
          .string()
          .required('em desenvolvimento I: digite a descrição da correção')
      })
    ),
    developments_2: yup.array().of(
      yup.object().shape({
        paragraph: yup.string().required('digite o paragrafo'),
        grade: yup.string().required('digite a nota'),
        patch_competence_1: yup
          .string()
          .required('em desenvolvimento II: digite a descrição da correção'),
        patch_competence_2: yup
          .string()
          .required('em desenvolvimento II: digite a descrição da correção'),
        patch_competence_3: yup
          .string()
          .required('em desenvolvimento II: digite a descrição da correção'),
        patch_competence_4: yup
          .string()
          .required('em desenvolvimento II: digite a descrição da correção')
      })
    )
  })

  try {
    await schema.validate(data, {
      abortEarly: false
    })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      return {
        dataToSave: undefined,
        error: err
      }
    }
  }

  const { introductions, conclusion, developments_1, developments_2 } = data

  for (let i = 0; i < introductions.length; i++) {
    // if (introductions[i]) {
    const val = Number(introductions[i].grade)

    if (val > 1000 || val < 0) {
      return {
        dataToSave: data,
        error: {
          errors: [`em introdução ${i + 1} a nota não é válida`]
        }
      }
    }
    // }
  }

  for (let i = 0; i < developments_1.length; i++) {
    if (developments_1[i]) {
      const val = Number(developments_1[i].grade)

      if (val > 1000 || val < 0) {
        return {
          dataToSave: data,
          error: {
            errors: [`em desenovlvimento I ${i + 1} a nota não é válida`]
          }
        }
      }
    } else {
      delete data.developments_1[i]
    }
  }

  for (let i = 0; i < developments_2.length; i++) {
    if (developments_2[i]) {
      const val = Number(developments_2[i].grade)

      if (val > 1000 || val < 0) {
        return {
          dataToSave: data,
          error: {
            errors: [`em desenvolvimento II ${i + 1} a nota não é válida`]
          }
        }
      }
    } else {
      delete data.developments_2[i]
    }
  }

  for (let i = 0; i < conclusion.length; i++) {
    if (conclusion[i]) {
      const val = Number(conclusion[i].grade)

      if (val > 1000 || val < 0) {
        return {
          dataToSave: data,
          error: {
            errors: [`em conclusão ${i + 1} a nota não é válida`]
          }
        }
      }
    } else {
      delete data.conslusion[i]
    }
  }

  data.gold_published = data.gold_published ? data.gold_published : false

  return {
    dataToSave: data,
    error: undefined
  }
}

export const mappersVideo = async (data: any): Promise<ResponseMappers> => {
  const schema = yup.object().shape({
    name: yup.string().required('digite o nome do vídeo'),
    link: yup.string().required('digite o link (url) do vídeo'),
    order: yup.string().required('digite a ordem do vídeo'),
    category: yup.string().required('selecione a categoria')
  })

  try {
    await schema.validate(data, {
      abortEarly: false
    })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      return {
        dataToSave: undefined,
        error: err
      }
    }
  }

  data.gold_published = data.gold_published ? data.gold_published : false
  data.published = data.published ? data.published : false

  return {
    dataToSave: data,
    error: undefined
  }
}

export const mappersEbook = async (data: any): Promise<ResponseMappers> => {
  const schema = yup.object().shape({
    name: yup.string().required('digite o nome do ebook'),
    order: yup.number().required('digite a ordem'),
    category: yup.string().required('selecione a categoria')
  })

  try {
    await schema.validate(data, {
      abortEarly: false
    })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      return {
        dataToSave: undefined,
        error: err
      }
    }
  }

  data.gold_published = data.gold_published ? data.gold_published : false
  data.published = data.published ? data.published : false

  return {
    dataToSave: data,
    error: undefined
  }
}

export const mappersAudio = async (data: any): Promise<ResponseMappers> => {
  const schema = yup.object().shape({
    name: yup.string().required('digite o nome do audio'),
    order: yup.number().required('digite a ordem'),
    category: yup.string().required('selecione a categoria')
  })

  try {
    await schema.validate(data, {
      abortEarly: false
    })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      return {
        dataToSave: undefined,
        error: err
      }
    }
  }

  data.gold_published = data.gold_published ? data.gold_published : false
  data.published = data.published ? data.published : false

  return {
    dataToSave: data,
    error: undefined
  }
}

export const mappersUsers = async (data: any): Promise<ResponseMappers> => {
  const schema = yup.object().shape({
    name: yup.string().required('digite o nome'),
    email: yup.string().required('digite o email'),
    profile: yup.string().required('selecione o perfil')
  })

  try {
    await schema.validate(data, {
      abortEarly: false
    })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      return {
        dataToSave: undefined,
        error: err
      }
    }
  }

  return {
    dataToSave: data,
    error: undefined
  }
}

export const mappersMarathon = async (data: any): Promise<ResponseMappers> => {
  const schema = yup.object().shape({
    name: yup.string().required('digite o nome do ebook'),
    date: yup.string().required('selecione a data de lançamento'),
    time: yup.string().required('selecione a hora de lançamento')
  })

  try {
    await schema.validate(data, {
      abortEarly: false
    })
  } catch (err: any) {
    if (err instanceof yup.ValidationError) {
      return {
        dataToSave: undefined,
        error: err
      }
    }
  }

  data.audio_published = data.audio_published ? data.audio_published : false
  data.published = false

  return {
    dataToSave: data,
    error: undefined
  }
}
