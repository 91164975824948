import { message, notification } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { mappersVideo } from 'utils/mappers'
import * as T from './types'

const VideoContext = createContext<T.VideoContextType>(
  T.videoContextDefaultValue
)

export const VideosProvider = ({ children }: T.VideoProps) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState<T.PaginationType>({
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  })

  const handleList = useCallback(
    async (page = 1, size = 10, search?: string) => {
      setLoading(true)
      const filter: any = {
        listAll: true,
        page,
        size
      }

      if (search) {
        filter.search = search
      }

      const url = new URLSearchParams(filter).toString()

      const response = await api.get(`/videos/?${url}`)
      setLoading(false)

      if (response.status >= 400) return

      const { total, next_page, previous_page, videos } = response.data

      setPagination({
        total,
        next_page,
        previous_page,
        current_page: page,
        pageSize: size,
        search
      })

      setResources(videos)
    },
    []
  )

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    setShowLoading(true)
    const response = await api.get(`/videos/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)

    setShowLoading(false)
  }, [])

  const handleCreate = useCallback(
    async (data: any) => {
      const { dataToSave, error } = await mappersVideo(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      setFormLoading(true)
      const response = await api.post('/videos', dataToSave)
      setFormLoading(false)

      if (response.status >= 400) return

      history.push('/videos')
      message.success('Vídeo criado com sucesso!')
    },
    [history]
  )

  const handleEdit = useCallback(
    async (data: any, id: string) => {
      setFormLoading(true)

      if (typeof data.category === 'object') {
        data.category = data.category.value
      }

      const { dataToSave, error } = await mappersVideo(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      const response = await api.put(`/videos/${id}`, dataToSave)
      setFormLoading(false)

      if (response.status >= 400) return
      history.push('/videos')
      message.success('Vídeo editado com sucesso!')
    },
    [history]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/videos/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Vídeo deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <VideoContext.Provider
      value={{
        loading,
        pagination,
        formLoading,
        resources,
        handleList,
        handleShow,
        handleCreate,
        handleEdit,
        handleDelete,
        initialData,
        showLoading
      }}
    >
      {children}
    </VideoContext.Provider>
  )
}

export const useVideos = () => useContext(VideoContext)
