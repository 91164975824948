export type EnterCodeProps = {
  name?: string
  id?: string
  children?: React.ReactNode
}

export type EnterCodeType = {
  loading: boolean
  formLoading: boolean
  resources: any[]

  handleList: (page?: number, pageSize?: number, search?: string) => void
  handleCreate: (name: string) => void
  handleDelete: (id: string) => void
}

export type PaginationType = {
  total: number
  next_page: number
  previous_page: number
  current_page: number
  pageSize: number
  search?: string
}

export const enterCodeDefaultValue: EnterCodeType = {
  loading: false,
  formLoading: false,
  resources: [],

  handleList: () => true,
  handleCreate: () => true,
  handleDelete: () => true
}
