import styled from 'styled-components'
import { Button as AntdButton, Table } from 'antd'
import WrapperLayout from 'components/molecules/WrapperLayout'

export const Container = styled.div``

export const Button = styled(AntdButton)`
  margin: 0 5px;
`

export const Layout = styled(WrapperLayout)``

export const List = styled(Table)``
export const { Column } = Table
