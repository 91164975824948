import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 20px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`
export const Title = styled.div`
  font-size: 23px;
  font-weight: 700;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 850px;
  width: 100%;
`
