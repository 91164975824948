import { useState } from 'react'
import * as S from './styles'
import { Button, Upload, message, Input, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { imageFiles } from 'utils/fileExtensions'

import NumberFormat from 'react-number-format'
import { useUsers } from 'contexts/users'

function Add() {
  const [options] = useState(['Aluno', 'Admin'])
  const { control, handleSubmit } = useForm()
  const { handleCreate = () => true, formLoading } = useUsers()
  const [image, setImage] = useState<any>()

  const onSubmit = (data: any) => {
    if (data.password.length < 6) {
      notification.error({
        description: 'A senha precisa ter no mínimo 6 caracteres',
        message: 'Atenção'
      })
      return
    }

    if (data.password !== data.password_2) {
      notification.error({
        description: 'As senhas não conferem',
        message: 'Atenção'
      })
      return
    }

    data.is_superadmin = data.profile === 'Admin'

    handleCreate({ ...data, image })
  }

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={formLoading}
        title="Adicionar usuário"
        backTo="/users"
      >
        <S.DoubleColumn className="together bottom">
          <Upload
            name="image"
            action=""
            fileList={image ? image.fileList : undefined}
            maxCount={1}
            onChange={(info) => setImage(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (!imageFiles.includes(file.type)) {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return imageFiles.includes(file.type) ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload do avatar</Button>
          </Upload>
        </S.DoubleColumn>
        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Nome completo</S.Label>
            <Controller as={<Input />} name="name" control={control} />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Label>Perfil</S.Label>
            <Controller
              as={
                <S.Select
                  options={options.map((option) => ({
                    label: option,
                    value: option
                  }))}
                />
              }
              name="profile"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>
        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Email</S.Label>
            <Controller
              as={<Input type="email" />}
              name="email"
              control={control}
            />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Label>Celular</S.Label>
            <Controller
              as={
                <NumberFormat
                  customInput={Input}
                  placeholder="(XX) XXXXX-XXXX"
                  format="(##) #####-####"
                />
              }
              name="phone"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>

        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Senha</S.Label>
            <Controller
              as={<Input type="password" />}
              name="password"
              control={control}
            />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Label>Repita a senha</S.Label>
            <Controller
              as={<Input type="password" />}
              name="password_2"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>

        <S.Footer>
          <Link to="/users">
            <Button htmlType="button" disabled={formLoading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={formLoading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Add
