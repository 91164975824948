import * as S from './styles'
import { Input } from 'antd'
import { Controller } from 'react-hook-form'
const { TextArea } = Input

type ThemeFormType = {
  index: number
  control: any
  preName: string
  hasFifth?: boolean
  defaultValues?: any
  name?: string
}

function ThemeFormTexts({
  index,
  control,
  preName,
  name,
  hasFifth = false,
  defaultValues
}: ThemeFormType) {
  return (
    <S.Container>
      <Controller
        as={<input defaultValue={name} type="hidden" />}
        name={`${preName}[${index}].name`}
        control={control}
      />
      {defaultValues && defaultValues.id && (
        <Controller
          as={<input defaultValue={defaultValues.id} type="hidden" />}
          name={`${preName}[${index}].id`}
          control={control}
          defaultValue={defaultValues && defaultValues.id}
        />
      )}
      <S.FieldWrapper>
        <S.Label>Paragrafo</S.Label>
        <Controller
          as={<TextArea rows={3} />}
          name={`${preName}[${index}].paragraph`}
          control={control}
          defaultValue={defaultValues && defaultValues.paragraph}
        />
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Label>Nota</S.Label>
        <Controller
          as={<Input type="number" />}
          name={`${preName}[${index}].grade`}
          control={control}
          defaultValue={defaultValues && defaultValues.grade}
        />
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Label>Descrição da Correção</S.Label>
        <Controller
          as={<Input />}
          name={`${preName}[${index}].patch_description`}
          control={control}
          defaultValue={defaultValues && defaultValues.patch_description}
        />
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Label>Correção Competência 1</S.Label>
        <Controller
          as={<Input />}
          name={`${preName}[${index}].patch_competence_1`}
          control={control}
          defaultValue={defaultValues && defaultValues.patch_competence_1}
        />
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Label>Correção Competência 2</S.Label>
        <Controller
          as={<Input />}
          name={`${preName}[${index}].patch_competence_2`}
          control={control}
          defaultValue={defaultValues && defaultValues.patch_competence_2}
        />
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Label>Correção Competência 3</S.Label>
        <Controller
          as={<Input />}
          name={`${preName}[${index}].patch_competence_3`}
          control={control}
          defaultValue={defaultValues && defaultValues.patch_competence_3}
        />
      </S.FieldWrapper>

      <S.FieldWrapper>
        <S.Label>Correção Competência 4</S.Label>
        <Controller
          as={<Input />}
          name={`${preName}[${index}].patch_competence_4`}
          control={control}
          defaultValue={defaultValues && defaultValues.patch_competence_4}
        />
      </S.FieldWrapper>

      {hasFifth && (
        <S.FieldWrapper>
          <S.Label>Correção Competência 5</S.Label>
          <Controller
            as={<Input />}
            name={`${preName}[${index}].patch_competence_5`}
            control={control}
            defaultValue={defaultValues && defaultValues.patch_competence_5}
          />
        </S.FieldWrapper>
      )}
    </S.Container>
  )
}

export default ThemeFormTexts
