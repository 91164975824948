import styled from 'styled-components'
import {
  Button as AntdButton,
  Table,
  Modal as AntdModal,
  Input,
  Popconfirm
} from 'antd'
import WrapperLayout from 'components/molecules/WrapperLayout'
import { CheckOutlined } from '@ant-design/icons'

export const Container = styled.div``

export const Button = styled(AntdButton)`
  margin: 0 5px;
`

export const Layout = styled(WrapperLayout)``

export const List = styled(Table)``
export const { Column } = Table
export const Modal = styled(AntdModal)``
export const Field = styled(Input)``
export const Confirm = styled(Popconfirm)``
export const CheckIcon = styled(CheckOutlined)`
  font-size: 20px;
  color: green;
`

export const ExternalURl = styled.a.attrs({
  target: '_blank'
})``
