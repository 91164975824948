import { useEffect } from 'react'
import * as S from './styles'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { dateBrFormatter } from 'utils/formatter'
import { useEnterCode } from 'contexts/enterCode'

function EnterCode() {
  const {
    handleList = () => true,
    resources,
    loading,
    handleDelete = () => true
  } = useEnterCode()

  useEffect(() => {
    handleList()
  }, [handleList])

  // edtitado

  return (
    <S.Container>
      <S.Layout
        title="Cupons"
        buttons={
          <Link to="/enter-code/add">
            <Button>
              <PlusOutlined />
              Adicionar
            </Button>
          </Link>
        }
      >
        <S.List
          dataSource={resources}
          loading={loading}
          pagination={false}
          rowKey="id"
        >
          <S.Column title="Código do Cupom" dataIndex="code" key="code" />
          <S.Column
            title="Data de expiração"
            dataIndex="vadility"
            key="vadility"
            render={(id: string, data: any) => (
              <span>{dateBrFormatter(data.validity)}</span>
            )}
          />

          <S.Column
            title="Ação"
            dataIndex="id"
            key="id"
            render={(id) => (
              <>
                <S.Confirm
                  title="Tem certeza que deseja deletar?"
                  onConfirm={() => handleDelete(id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <Button>Apagar</Button>
                </S.Confirm>
              </>
            )}
          />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default EnterCode
