import { createContext, useContext, useState, useCallback } from 'react'
import { randomBetween } from 'utils/randomKey'

export type ProgressProps = {
  children?: React.ReactNode
}

type UserContextType = {
  changeProgress: (min: number, max?: number) => void
  progress: number
}

const progressContextDefaultValue: UserContextType = {
  progress: 0,
  changeProgress: () => true
}

const ProgressContext = createContext<UserContextType>(
  progressContextDefaultValue
)

export const ProgressProvider = ({ children }: ProgressProps) => {
  const [progress, setProgress] = useState(0)

  const changeProgress = useCallback((min: number, max?: number) => {
    let value: number = min

    if (max) {
      value = randomBetween(min, max)
    }

    setProgress(value)

    if (value === 100) {
      setTimeout(() => {
        setProgress(0)
      }, 2000)
    }
  }, [])

  return (
    <ProgressContext.Provider
      value={{
        progress,
        changeProgress
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}

export const useProgress = () => useContext(ProgressContext)
