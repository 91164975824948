import { message, notification } from 'antd'
import { createContext, useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import api from 'services/api'
import { mappersThemes } from 'utils/mappers'
import * as T from './types'

const StudentsContext = createContext<T.StudentsContextType>(
  T.studentsContextDefaultValue
)

export const StudentsProvider = ({ children }: T.StudentsProps) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [formLoading, setFormLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [initialData, setInitialData] = useState<any>(undefined)
  const [showLoading, setShowLoading] = useState(false)
  const [pagination, setPagination] = useState<T.PaginationType>({
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  })

  const handleList = useCallback(
    async (page = 1, pageSize = 10, search?: string) => {
      setLoading(true)
      let querySearch = ''

      if (search) {
        querySearch = `&search=${search}`
      }

      const response = await api.get(
        `/user/students/?page=${page}&size=${pageSize}${querySearch}`
      )
      setLoading(false)

      if (response.status >= 400) return

      const { total, next_page, previous_page, users } = response.data

      setPagination({
        total,
        next_page,
        previous_page,
        current_page: page,
        pageSize,
        search
      })

      setResources(users)
    },
    []
  )

  const handleShow = useCallback(async (id: string): Promise<string | void> => {
    setShowLoading(true)
    const response = await api.get(`/user/${id}`)
    if (response.status >= 400) return

    setInitialData(response.data)
    setShowLoading(false)
  }, [])

  const handleCreate = useCallback(
    async (data: any) => {
      const { dataToSave, error } = await mappersThemes(data)

      if (error) {
        notification.error({
          message: 'Atenção',
          description: error.errors[0]
        })
        return
      }

      console.log(dataToSave)

      setFormLoading(true)
      const response = await api.post('/user', dataToSave)
      setFormLoading(false)

      if (response.status >= 400) return

      history.push('/')
      message.success('Usuário criado com sucesso!')
    },
    [history]
  )

  const handleEdit = useCallback(
    async (data: any, id: string) => {
      setFormLoading(true)

      if (typeof data.access_type === 'object') {
        data.access_type = data.access_type.value
      }

      const response = await api.put(`/user/${id}`, data)
      setFormLoading(false)

      if (response.status >= 400) return
      history.push('/students')
      message.success('Aluno editado com sucesso!')
    },
    [history]
  )

  const handleDelete = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await api.delete(`/user/${id}`)
      setLoading(false)

      if (response.status >= 400) return

      message.success('Aluno deletado com sucesso!')
      handleList()
    },
    [handleList]
  )

  const handleChangeLevels = useCallback(
    async (users: string[], level: string) => {
      setLoading(true)
      const response = await api.patch(`/user/change-level`, {
        users,
        level
      })
      setLoading(false)

      if (response.status >= 400) return

      message.success('Usuários alterados com sucesso!')
      handleList()
    },
    [handleList]
  )

  return (
    <StudentsContext.Provider
      value={{
        loading,
        pagination,
        formLoading,
        resources,
        handleList,
        handleShow,
        handleCreate,
        handleEdit,
        handleDelete,
        initialData,
        showLoading,
        handleChangeLevels
      }}
    >
      {children}
    </StudentsContext.Provider>
  )
}

export const useStudents = () => useContext(StudentsContext)
