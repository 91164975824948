import { useState, useEffect } from 'react'
import * as S from './styles'
import { Button, Upload, message, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useForm, Controller } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { imageFiles } from 'utils/fileExtensions'

import { useUsers } from 'contexts/users'
import NumberFormat from 'react-number-format'

type ParamsType = {
  id: string
}

function Edit() {
  const { id } = useParams<ParamsType>()
  const [options] = useState(['Aluno', 'Admin'])
  const { control, handleSubmit, setValue } = useForm()
  const {
    handleEdit = () => true,
    formLoading,
    handleShow = () => true,
    initialData,
    showLoading
  } = useUsers()

  const [image, setImage] = useState<any>()

  useEffect(() => {
    if (id) {
      handleShow(id)
    }
  }, [handleShow, id])

  useEffect(() => {
    if (initialData) {
      setValue('name', initialData.name)
      setValue('phone', initialData.phone)
      setValue('email', initialData.email)
      setValue(
        'profile',
        initialData.is_superadmin
          ? {
              label: 'Aluno',
              value: 'Aluno'
            }
          : {
              label: 'Admin',
              value: 'Admin'
            }
      )
    }
  }, [initialData, options, setValue])

  const onSubmit = (data: any) => {
    if (typeof data.profile === 'object') {
      data.profile = data.profile.value
    }

    console.log(data.profile)

    data.is_superadmin = data.profile === 'Admin'

    handleEdit({ ...data, image }, id)
  }

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={formLoading}
        title="Adicionar usuário"
        backTo="/users"
      >
        <Upload
          name="image"
          action=""
          fileList={image ? image.fileList : undefined}
          maxCount={1}
          onChange={(info) => setImage(handleChangeFile(info))}
          beforeUpload={(file) => {
            if (!imageFiles.includes(file.type)) {
              message.error(`${file.name} não é um arquivo válido`)
            }
            return imageFiles.includes(file.type) ? true : Upload.LIST_IGNORE
          }}
        >
          <Button
            style={{ marginBottom: initialData && initialData.avatar ? 0 : 13 }}
            icon={<UploadOutlined />}
          >
            Upload do avatar
          </Button>
        </Upload>
        {initialData && initialData.avatar && (
          <>
            <S.DirectionToFile
              target="_blank"
              href={initialData ? initialData.avatar : ''}
            >
              Acesse o avatar aqui
            </S.DirectionToFile>

            <S.Small>
              Se você deixar o campo de upload em branco, o arquivo permanecerá
              do último upload
            </S.Small>
          </>
        )}
        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Nome completo</S.Label>
            <Controller as={<Input />} name="name" control={control} />
          </S.FieldWrapper>

          {!showLoading && (
            <S.FieldWrapper>
              <S.Label>Perfil</S.Label>
              <Controller
                as={
                  <S.Select
                    options={options.map((option) => ({
                      label: option,
                      value: option
                    }))}
                    value={
                      initialData && !initialData.is_superadmin
                        ? {
                            label: 'Aluno',
                            value: 'Aluno'
                          }
                        : {
                            label: 'Admin',
                            value: 'Admin'
                          }
                    }
                  />
                }
                name="profile"
                control={control}
                defaultValue={
                  initialData && !initialData.is_superadmin
                    ? {
                        label: 'Aluno',
                        value: 'Aluno'
                      }
                    : {
                        label: 'Admin',
                        value: 'Admin'
                      }
                }
              />
            </S.FieldWrapper>
          )}
        </S.DoubleColumn>
        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Email</S.Label>
            <Controller
              as={<Input type="email" />}
              name="email"
              control={control}
            />
          </S.FieldWrapper>

          <S.FieldWrapper>
            <S.Label>Celular</S.Label>
            <Controller
              as={
                <NumberFormat
                  customInput={Input}
                  placeholder="(XX) XXXXX-XXXX"
                  format="(##) #####-####"
                />
              }
              name="phone"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>
        <S.Footer>
          <Link to="/users">
            <Button htmlType="button" disabled={formLoading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={formLoading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Edit
