import { useState } from 'react'
import * as S from './styles'
import { Button, Switch, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { useVideos } from 'contexts/videos'

function Add() {
  const [options] = useState([
    'Escolas Militares',
    'Universidades RJ e SP',
    'Concurso',
    'Enem',
    'Enem 2021',
    'UERJ',
    'Coaching',
    'Vídeos Curtos/Esquema em Vídeo',
    'Quadros',
    'Home App'
  ])
  const { control, handleSubmit } = useForm()
  const { handleCreate = () => true, loading } = useVideos()

  const onSubmit = (data: any) => {
    handleCreate(data)
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={loading}
        title="Adicionar Vídeo"
        backTo="/videos"
      >
        <S.SwitchWrapper>
          <Controller
            render={(field) => (
              <Switch onChange={(checked: any) => field.onChange(checked)} />
            )}
            name="gold_published"
            control={control}
          />
          <S.Label>Publicar ouro</S.Label>
        </S.SwitchWrapper>
        <S.SwitchWrapper>
          <Controller
            render={(field) => (
              <Switch onChange={(checked: any) => field.onChange(checked)} />
            )}
            name="published"
            control={control}
          />
          <S.Label>Publicar</S.Label>
        </S.SwitchWrapper>
        <S.FieldWrapper>
          <S.Label>Título</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Link (URL) do Vídeo</S.Label>
          <Controller
            as={<Input placeholder="https://youtube.com/watch?..." />}
            name="link"
            control={control}
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Ordem</S.Label>
          <Controller
            as={<Input type="number" />}
            name="order"
            control={control}
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Categoria</S.Label>
          <Controller
            as={
              <S.Select
                options={options.map((option) => ({
                  label: option,
                  value: option
                }))}
              />
            }
            name="category"
            control={control}
          />
        </S.FieldWrapper>

        <S.Footer>
          <Link to="/videos">
            <Button htmlType="button" disabled={loading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={loading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Add
