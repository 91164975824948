import { useState, useEffect } from 'react'
import * as S from './styles'
import { Button, Upload, message, Switch, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useForm, Controller } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { imageFiles } from 'utils/fileExtensions'
import { useEbooks } from 'contexts/ebooks'

type ParamsType = {
  id: string
}

function Edit() {
  const { id } = useParams<ParamsType>()

  const [options] = useState([
    'PPT Interdisciplinar',
    'Temas semanais',
    'E-books',
    'Redações Exemplares'
  ])
  const { control, handleSubmit, setValue } = useForm()
  const {
    handleEdit = () => true,
    formLoading,
    handleShow = () => true,
    initialData,
    showLoading
  } = useEbooks()
  const [file, setFile] = useState<any>()
  const [image, setImage] = useState<any>()

  useEffect(() => {
    if (id) {
      handleShow(id)
    }
  }, [handleShow, id])

  useEffect(() => {
    if (initialData) {
      setValue('name', initialData.name)
      setValue('order', initialData.order)
      setValue('category', initialData.category)
    }
  }, [initialData, options, setValue])

  const onSubmit = (data: any) => {
    handleEdit({ ...data, image, file }, id)
  }

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={formLoading}
        title="Editar E-book"
        backTo="/ebooks"
      >
        {!showLoading && (
          <>
            <S.SwitchWrapper>
              <Controller
                render={(field) => (
                  <Switch
                    onChange={(checked: any) => field.onChange(checked)}
                    defaultChecked={
                      !!(initialData && initialData.gold_published)
                    }
                  />
                )}
                name="gold_published"
                control={control}
                defaultValue={!!(initialData && initialData.gold_published)}
              />
              <S.Label>Publicar ouro</S.Label>
            </S.SwitchWrapper>
            <S.SwitchWrapper>
              <Controller
                render={(field) => (
                  <Switch
                    onChange={(checked: any) => field.onChange(checked)}
                    defaultChecked={!!(initialData && initialData.published)}
                  />
                )}
                name="published"
                control={control}
                defaultValue={!!(initialData && initialData.published)}
              />
              <S.Label>Publicar</S.Label>
            </S.SwitchWrapper>
          </>
        )}
        <S.Small>
          Se você deixar os campos de upload em branco, os arquivos permanecerão
          do último upload
        </S.Small>
        <S.DoubleColumn className="together bottom">
          <S.UploadWrapper>
            <Upload
              name="file"
              action=""
              fileList={file ? file.fileList : undefined}
              maxCount={1}
              onChange={(info) => setFile(handleChangeFile(info))}
              beforeUpload={(file) => {
                if (file.type !== 'application/pdf') {
                  message.error(`${file.name} não é um arquivo válido`)
                }
                return file.type === 'application/pdf'
                  ? true
                  : Upload.LIST_IGNORE
              }}
            >
              <Button icon={<UploadOutlined />}>Upload do file</Button>
            </Upload>
            <S.DirectionToFile
              target="_blank"
              href={initialData ? initialData.pdf : ''}
            >
              Acesse o PDF aqui
            </S.DirectionToFile>
          </S.UploadWrapper>
          <S.UploadWrapper>
            <Upload
              name="image"
              action=""
              fileList={image ? image.fileList : undefined}
              maxCount={1}
              onChange={(info) => setImage(handleChangeFile(info))}
              beforeUpload={(file) => {
                if (!imageFiles.includes(file.type)) {
                  message.error(`${file.name} não é um arquivo válido`)
                }
                return imageFiles.includes(file.type)
                  ? true
                  : Upload.LIST_IGNORE
              }}
            >
              <Button icon={<UploadOutlined />}>Upload da imagem</Button>
            </Upload>
            <S.DirectionToFile
              target="_blank"
              href={initialData ? initialData.image : ''}
            >
              Acesse a imagem aqui
            </S.DirectionToFile>
          </S.UploadWrapper>
        </S.DoubleColumn>
        <S.FieldWrapper>
          <S.Label>Título</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>

        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Ordem</S.Label>
            <Controller
              as={<Input placeholder="1" type="number" />}
              name="order"
              control={control}
            />
          </S.FieldWrapper>
          {!showLoading && (
            <>
              <S.FieldWrapper>
                <S.Label>Categoria</S.Label>
                <Controller
                  as={
                    <S.Select
                      options={options.map((option) => ({
                        label: option,
                        value: option
                      }))}
                      value={
                        initialData && {
                          label: initialData.category,
                          value: initialData.category
                        }
                      }
                    />
                  }
                  name="category"
                  control={control}
                  defaultValue={
                    initialData && {
                      label: initialData.category,
                      value: initialData.category
                    }
                  }
                />
              </S.FieldWrapper>
            </>
          )}
        </S.DoubleColumn>
        <S.Footer>
          <Link to="/ebooks">
            <Button htmlType="button" disabled={formLoading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={formLoading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Edit
