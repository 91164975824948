import { useCallback, useState } from 'react'

import * as S from './styles'

type WrapperLayoutType = {
  title: string
  buttons?: React.ReactNode
  children?: React.ReactNode
  searchable?: boolean
  loading?: boolean
  onSearch?:
    | ((
        value: string,
        event?:
          | React.ChangeEvent<HTMLInputElement>
          | React.MouseEvent<HTMLElement, MouseEvent>
          | React.KeyboardEvent<HTMLInputElement>
          | undefined
      ) => void)
    | undefined
}

function WrapperLayout({
  title,
  buttons,
  loading = false,
  searchable = false,
  onSearch = () => true,
  children
}: WrapperLayoutType) {
  const [value, setValue] = useState('')

  const handleClear = useCallback(() => {
    setValue('')
    onSearch('')
  }, [onSearch])

  const suffix = <S.CloseIcon isVisible={value !== ''} onClick={handleClear} />

  return (
    <S.Container>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.AreaButtons>
          {searchable && (
            <S.Search
              placeholder="Pesquisar"
              suffix={suffix}
              value={value}
              onSearch={onSearch}
              onChange={(event) => setValue(event.target.value)}
              enterButton
            />
          )}
          {buttons}
        </S.AreaButtons>
      </S.Header>
      <S.Content>
        {loading ? <S.Loader active paragraph={{ rows: 10 }} /> : children}
      </S.Content>
    </S.Container>
  )
}

export default WrapperLayout
