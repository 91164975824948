export type YoutubeVideosYype = {
  url: string
  title: string
  description: string
}

const youtubeVideos: YoutubeVideosYype[] = [
  {
    url: 'https://www.youtube.com/watch?v=NfbELRUToZw&ab_channel=DouglasPessoa-Desenvolvimento',
    title: 'Como reportar um erro no sistema para o desenvolvedor',
    description:
      'Aconteceu algum problema no nosso sistema? Sem desespero! Isso é super normal e acontecem nas melhores empresas. \nEsse tutorial é para conseguirmos resolver o problema o mais rápido possível. Talvez sem a sua ajuda, o problema pode demorar semanas ou até meses para ser encontrado, então, fiz esse tutorial para você conseguir diminuir esse prazo para um ou alguns dias. \nLembrando: Ele é apenas um exemplo, mas serve para quase todos os casos!'
  }
]

export default youtubeVideos
