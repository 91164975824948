import styled from 'styled-components'
import BahSidebar from 'components/molecules/Sidebar'
import BaHeader from 'components/molecules/Header'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/lib/locale/pt_BR'

export const AntdConfigProvider = styled(ConfigProvider).attrs({
  locale: ptBR
})``

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
export const Content = styled.main`
  width: 100%;

  height: fit-content;
  overflow-y: auto;
  overflow-x: auto;

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #ffb231;
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #9a4332;
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const Sidebar = styled(BahSidebar)``
export const Header = styled(BaHeader)``
