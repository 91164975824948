export type ThemeProps = {
  name?: string
  id?: string
  children?: React.ReactNode
}

export type ThemeContextType = {
  loading: boolean
  formLoading: boolean
  initialData: any
  resources: any[]
  pagination: PaginationType
  handleList: (page?: number, pageSize?: number, search?: string) => void
  handleShow: (id: string) => Promise<string | void>
  handleCreate: (name: string) => void
  handleEdit: (name: string, id: string) => void
  handleDelete: (id: string) => void
}

export type PaginationType = {
  total: number
  next_page: number
  previous_page: number
  current_page: number
  pageSize: number
  search?: string
}

export const themeContextDefaultValue: ThemeContextType = {
  loading: false,
  formLoading: false,
  resources: [],
  initialData: undefined,
  pagination: {
    total: 0,
    next_page: 1,
    previous_page: 1,
    current_page: 1,
    pageSize: 10,
    search: undefined
  },
  handleList: () => true,
  handleShow: async () => '',
  handleCreate: () => true,
  handleEdit: () => true,
  handleDelete: () => true
}
