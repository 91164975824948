import WrapperLayout from 'components/molecules/WrapperLayout'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 10px;
  padding-bottom: 30px;
`

export const Layout = styled(WrapperLayout)``

export const Videos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
`

export const Video = styled.div`
  display: flex;
  width: 100%;
  gap: 0 20px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background: rgba(188, 188, 188, 0.1);
  }
`

export const Thumb = styled.img`
  max-width: 200px;
  object-fit: contain;
`

export const Texts = styled.div`
  width: calc(100% - 180px);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px 0;
`
export const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 19px;
`

export const Desc = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  white-space: pre-wrap;
`
export const Anchor = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`

export const VideoArea = styled.div`
  width: 1000px;
`
