import { useStudents } from 'contexts/students'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as S from './styles'
import { Button, notification, Modal, Select } from 'antd'
import { useUsers } from 'contexts/users'

function Students() {
  const [options] = useState([
    {
      label: 'Bronze',
      value: 'Bronze'
    },
    {
      label: 'Prata',
      value: 'Prata'
    },
    {
      label: 'Ouro',
      value: 'Ouro'
    }
  ])
  const {
    handleList = () => true,
    resources,
    loading,
    handleDelete = () => true,
    pagination,
    handleChangeLevels
  } = useStudents()
  const [ids, setIds] = useState<string[]>([])
  const [modal, setModal] = useState(false)
  const [plan, setPlan] = useState('')

  useEffect(() => {
    handleList()
  }, [handleList])

  const rowSelection = {
    selectedRowKeys: ids,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(selectedRows.map((rows) => rows.id))
      setIds(selectedRows.map((rows) => rows.id))
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  }

  function handleChangePlan() {
    if (ids.length === 0) {
      notification.error({
        message: 'Atenção',
        description: 'Selecione um ou mais alunos para mudar o plano'
      })
      return
    }

    setModal(true)
  }

  function handleSubmit() {
    if (plan === '') {
      return
    }

    handleChangeLevels(ids, plan)

    setModal(false)
    setIds([])
    setPlan('')
  }

  return (
    <S.Container>
      <Modal
        title="Alteração de nivel dos usuários"
        visible={modal}
        okText="Alterar"
        onOk={() => handleSubmit()}
        onCancel={() => setModal(false)}
      >
        <label>Selecione o plano</label> <br />
        <Select
          style={{ width: '100%' }}
          options={options}
          placeholder="Selecione um plano"
          onChange={(e) => setPlan(e)}
        />
      </Modal>
      <S.Layout
        title="Alunos"
        searchable
        onSearch={(value) => handleList(1, 10, value)}
      >
        <Button onClick={handleChangePlan} style={{ marginBottom: 10 }}>
          Alterar Planos
        </Button>
        <S.List
          dataSource={resources}
          loading={loading}
          rowKey="id"
          onChange={(pagination) =>
            handleList(pagination.current, pagination.pageSize)
          }
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            total: pagination.total,
            current: pagination.current_page
          }}
        >
          <S.Column title="Nome" dataIndex="name" key="name" />
          {/* <S.Column title="E-mail" dataIndex="email" key="email" /> */}

          <S.Column title="Média" dataIndex="media" key="media" />
          <S.Column title="Plano" dataIndex="access_type" key="access_type" />
          <S.Column
            title="Ação"
            dataIndex="id"
            key="id"
            render={(id) => (
              <>
                <Link to={`/students/edit/${id}`}>
                  <S.Button>Editar</S.Button>
                </Link>
                <S.Confirm
                  title="Tem certeza que deseja deletar?"
                  onConfirm={() => handleDelete(id)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <S.Button>Apagar</S.Button>
                </S.Confirm>
              </>
            )}
          />
        </S.List>
      </S.Layout>
    </S.Container>
  )
}

export default Students
