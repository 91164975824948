import styled from 'styled-components'
import HRSelect from 'components/atoms/Select'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 850px;
  width: 100%;
  padding: 10px;
`
export const Form = styled.form`
  width: 100%;
`
export const Select = styled(HRSelect)``

export const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: 0 10px;
  justify-content: flex-end;
  margin-top: 30px;
`
export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  gap: 0 10px;
`

export const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 0 10px;
`

export const Label = styled.label``

export const DoubleColumn = styled.div`
  width: 100%;
  display: flex;
  gap: 0 20px;
  justify-content: space-between;
  flex-direction: row;

  &.bottom {
    margin-bottom: 30px;
  }

  &.column {
    flex-direction: column;
  }

  &.together {
    justify-content: flex-start;
    gap: 0 50px;
  }
`

export const Small = styled.small`
  font-size: 12px;
  color: orange;
  margin-bottom: 15px;
`

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DirectionToFile = styled.a`
  font-size: 12px;
  margin-top: 13px;
`
