import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  z-index: 100;
`

const loader = (x: number) => keyframes`
  0% {
    padding-left: 0;
  }

  100% {
    padding-left: ${`${x}%`};
  }
`

export const Loader = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  position: absolute;
  bottom: -2px;
  right: 0;
  left: 0;
  height: 3px;
  border-radius: 0 2px 2px 0;
  background-color: #1890ff;
  transition: 0.5s width;
  animation: ${({ progress }) => loader(progress)} linear infinite 1s;
  overflow: hidden;
`

export const Ghost = styled.div`
  width: 20px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`
