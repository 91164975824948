export const removeSpecialCharacters = (text: string): string => {
  text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  return text.replace(/[&/\\#,+()$~%.'":*?!<>{}]/g, '')
}

export const removeSpecialCharactersAndBlank = (text: string): string => {
  text = text.trim()
  text = removeSpecialCharacters(text)
  return text.replace(/ /g, '_')
}
