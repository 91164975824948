import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export { default as Home } from './Home'
export { default as Login } from './Login'
export { default as Themes } from './Themes'
export { default as ThemesAdd } from './Themes/Add'
export { default as ThemesEdit } from './Themes/Edit'
export { default as Students } from './Students'
export { default as StudentsEdit } from './Students/Edit'
export { default as Videos } from './Videos'
export { default as VideosAdd } from './Videos/Add'
export { default as VideosEdit } from './Videos/Edit'
export { default as Ebooks } from './Ebooks'
export { default as EbooksAdd } from './Ebooks/Add'
export { default as EbooksEdit } from './Ebooks/Edit'
export { default as Audios } from './Audios'
export { default as AudiosAdd } from './Audios/Add'
export { default as AudiosEdit } from './Audios/Edit'
export { default as Marathon } from './Marathon'
export { default as MarathonAdd } from './Marathon/Add'
export { default as MarathonEdit } from './Marathon/Edit'
export { default as Users } from './Users'
export { default as UsersAdd } from './Users/Add'
export { default as UsersEdit } from './Users/Edit'
export { default as Suggestions } from './Suggestions'
export { default as SuggestionsEdit } from './Suggestions/Edit'
export { default as Corrections } from './Corrections'
export { default as Tutorials } from './Tutorials'
export { default as EnterCode } from './EnterCode'
export { default as EnterCodeAdd } from './EnterCode/Add'

function Index() {
  const history = useHistory()

  useEffect(() => {
    const token = localStorage.getItem('HORADAREDACAO_AUTH')

    if (token) {
      history.push('/home')
    } else {
      history.push('/login')
    }
  }, [history])

  return <div />
}

export default Index
