import * as S from './styles'
import { Button, Switch, Input } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'

import FormWrapper from 'components/molecules/FormWrapper'

import { useEffect, useState } from 'react'
import { useVideos } from 'contexts/videos'

type ParamsType = {
  id: string
}

const values = [
  { label: 'Escolas Militares', value: 'Escolas Militares' },
  { label: 'Universidades RJ e SP', value: 'Universidades RJ e SP' },
  { label: 'Concurso', value: 'Concurso' },
  { label: 'Enem', value: 'Enem' },
  { label: 'Enem 2021', value: 'Enem 2021' },
  { label: 'UERJ', value: 'UERJ' },
  { label: 'Coaching', value: 'Coaching' },
  {
    label: 'Vídeos Curtos/Esquema em Vídeo',
    value: 'Vídeos Curtos/Esquema em Vídeo'
  },
  { label: 'Quadros', value: 'Quadros' },
  { label: 'Home App', value: 'Home App' }
]

function Edit() {
  const [items] = useState([
    'Escolas Militares',
    'Universidades RJ e SP',
    'Concurso',
    'Enem',
    'Enem 2021',
    'UERJ',
    'Coaching',
    'Vídeos Curtos/Esquema em Vídeo',
    'Quadros',
    'Home App'
  ])
  const [options] = useState(
    items.map((option) => ({
      label: option,
      value: option
    }))
  )
  const { id } = useParams<ParamsType>()
  const { control, handleSubmit, setValue } = useForm()
  const {
    handleEdit = () => true,
    loading,
    handleShow = () => true,
    initialData,
    showLoading
  } = useVideos()

  useEffect(() => {
    if (id) {
      handleShow(id)
    }
  }, [handleShow, id])

  useEffect(() => {
    if (initialData) {
      setValue('name', initialData.name)
      setValue('order', initialData.order)
      setValue('link', initialData.link)
      setValue('category', initialData.category)
    }
  }, [initialData, options, setValue])

  const onSubmit = (data: any) => {
    handleEdit(data, id)
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={loading}
        title="Adicionar Vídeo"
        backTo="/videos"
      >
        {!showLoading && (
          <>
            <S.SwitchWrapper>
              <Controller
                render={(field) => (
                  <Switch
                    onChange={(checked: any) => field.onChange(checked)}
                    defaultChecked={
                      !!(initialData && initialData.gold_published)
                    }
                  />
                )}
                name="gold_published"
                control={control}
                defaultValue={!!(initialData && initialData.gold_published)}
              />
              <S.Label>Publicar ouro</S.Label>
            </S.SwitchWrapper>
            <S.SwitchWrapper>
              <Controller
                render={(field) => (
                  <Switch
                    onChange={(checked: any) => field.onChange(checked)}
                    defaultChecked={!!(initialData && initialData.published)}
                  />
                )}
                name="published"
                control={control}
                defaultValue={!!(initialData && initialData.published)}
              />
              <S.Label>Publicar</S.Label>
            </S.SwitchWrapper>
          </>
        )}
        <S.FieldWrapper>
          <S.Label>Título</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Link (URL) do Vídeo</S.Label>
          <Controller
            as={<Input placeholder="https://youtube.com/watch?..." />}
            name="link"
            control={control}
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Ordem</S.Label>
          <Controller
            as={<Input type="number" />}
            name="order"
            control={control}
          />
        </S.FieldWrapper>

        {!showLoading && (
          <S.FieldWrapper>
            <S.Label>Categoria</S.Label>
            <Controller
              as={
                <S.Select
                  options={values}
                  value={
                    initialData && {
                      label: initialData.category,
                      value: initialData.category
                    }
                  }
                />
              }
              name="category"
              control={control}
              defaultValue={
                initialData && {
                  label: initialData.category,
                  value: initialData.category
                }
              }
            />
          </S.FieldWrapper>
        )}

        <S.Footer>
          <Link to="/videos">
            <Button htmlType="button" disabled={loading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={loading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Edit
