import { useState } from 'react'
import * as S from './styles'
import { Button, Upload, message, Switch, Input, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import FormWrapper from 'components/molecules/FormWrapper'
import { audioFiles, imageFiles } from 'utils/fileExtensions'
import { useMarathon } from 'contexts/marathon'

function Add() {
  const { control, handleSubmit } = useForm()
  const { handleCreate = () => true, formLoading } = useMarathon()
  const [file, setFile] = useState<any>()
  const [image, setImage] = useState<any>()
  const [audio, setAudio] = useState<any>()

  const onSubmit = (data: any) => {
    if (!file || !image || !audio) {
      notification.warning({
        message: 'Atenção',
        description: 'para salvar é necessário enviar os arquivos'
      })
      return
    }

    handleCreate({ ...data, image, file, audio })
  }

  const handleChangeFile = (data: any) => {
    if (!data || data.fileList.length === 0) return data
    data.fileList[0].status = 'done'

    return data
  }

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper
        disabledBack={formLoading}
        title="Adicionar Maratona"
        backTo="/marathon"
      >
        {/* <S.SwitchWrapper>
          <Controller
            render={(field) => (
              <Switch onChange={(checked: any) => field.onChange(checked)} />
            )}
            name="audio_published"
            control={control}
          />
          <S.Label>Publicar Áudio</S.Label>
        </S.SwitchWrapper> */}
        <S.Space>
          <Upload
            name="audio"
            action=""
            fileList={audio ? audio.fileList : undefined}
            maxCount={1}
            onChange={(info) => setAudio(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (!audioFiles.includes(file.type)) {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return audioFiles.includes(file.type) ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload do audio</Button>
          </Upload>
        </S.Space>
        <S.Space>
          <Upload
            name="image"
            action=""
            fileList={image ? image.fileList : undefined}
            maxCount={1}
            onChange={(info) => setImage(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (!imageFiles.includes(file.type)) {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return imageFiles.includes(file.type) ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload da imagem</Button>
          </Upload>
        </S.Space>
        <S.Space>
          <Upload
            name="file"
            action=""
            fileList={file ? file.fileList : undefined}
            maxCount={1}
            onChange={(info) => setFile(handleChangeFile(info))}
            beforeUpload={(file) => {
              if (file.type !== 'application/pdf') {
                message.error(`${file.name} não é um arquivo válido`)
              }
              return file.type === 'application/pdf' ? true : Upload.LIST_IGNORE
            }}
          >
            <Button icon={<UploadOutlined />}>Upload do PDF</Button>
          </Upload>
        </S.Space>
        <S.FieldWrapper>
          <S.Label>Título</S.Label>
          <Controller as={<Input />} name="name" control={control} />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Ordem</S.Label>
          <Controller
            as={<Input type="number" />}
            name="order"
            control={control}
          />
        </S.FieldWrapper>

        <S.FieldWrapper>
          <S.Label>Vídeo</S.Label>
          <Controller
            as={<Input placeholder="https://youtube.com/watch?" />}
            name="video_url"
            control={control}
          />
        </S.FieldWrapper>

        <S.DoubleColumn>
          <S.FieldWrapper>
            <S.Label>Data de lançamento</S.Label>
            <Controller
              as={<Input type="date" />}
              name="date"
              control={control}
            />
          </S.FieldWrapper>
          <S.FieldWrapper>
            <S.Label>Hora de lançamento</S.Label>
            <Controller
              as={<Input type="time" />}
              name="time"
              control={control}
            />
          </S.FieldWrapper>
        </S.DoubleColumn>
        <S.Footer>
          <Link to="/marathon">
            <Button htmlType="button" disabled={formLoading} type="text">
              Cancelar
            </Button>
          </Link>
          <Button htmlType="submit" loading={formLoading} type="primary">
            Salvar
          </Button>
        </S.Footer>
      </FormWrapper>
    </S.Form>
  )
}

export default Add
