import * as S from './styles'
import { Link } from 'react-router-dom'

function Sidebar() {
  return (
    <S.Container>
      <S.Image>
        <S.Logo />
      </S.Image>
      <S.Items>
        <S.ListItems>
          <Link to="/home">
            <S.Item>
              <S.HomeIcon />
              Home
            </S.Item>
          </Link>
          <Link to="/themes">
            <S.Item>
              <S.ThemeIcon />
              Temas
            </S.Item>
          </Link>
          <Link to="/students">
            <S.Item>
              <S.StudentIcon />
              Alunos
            </S.Item>
          </Link>
          <Link to="/videos">
            <S.Item>
              <S.VideoIcon />
              Vídeos
            </S.Item>
          </Link>
          <Link to="/ebooks">
            <S.Item>
              <S.BookIcon />
              E-books
            </S.Item>
          </Link>
          <Link to="/podcasts">
            <S.Item>
              <S.PodcastIcon />
              Audios
            </S.Item>
          </Link>
          <Link to="/corrections">
            <S.Item>
              <S.CorrectionsIcons />
              Correções
            </S.Item>
          </Link>
          <Link to="/suggestions">
            <S.Item>
              <S.SuggestionsIcon />
              Sugestões
            </S.Item>
          </Link>
          <Link to="/marathon">
            <S.Item>
              <S.MarathonIcon />
              Maratona
            </S.Item>
          </Link>
          <Link to="/users">
            <S.Item>
              <S.UsersIcon />
              Usuários
            </S.Item>
          </Link>
        </S.ListItems>
      </S.Items>
    </S.Container>
  )
}

export default Sidebar
